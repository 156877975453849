
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Logo from "../../components/Logo";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  loginUserFn,
  resetNewUserPassword,
  sendEmailForgotPass,
  setNewPassword,
} from "../auth/authServices";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { resetPasswordNewUserSchema } from "./validation";
import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import { CustomError } from "../addLeads/interface";

const NewUserResetPassword  = ({open,setOpen}:any) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const searchUrl = new URLSearchParams(decodeURIComponent(window.location.search));
  const organisationName = searchUrl.get("organisationName");
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordNewUserSchema),
  });

  
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    setSubmitClicked(true);
    const payload = {
      email: searchUrl.get("email") || "",
      code: searchUrl.get("code") || "",
      newPassword: values?.password,
    };

    if (executeRecaptcha) {
      const Token = await executeRecaptcha("Login");
      const loginPayload = {
        email: searchUrl.get("email") || "",
        password: values?.password,
        captchaToken: Token,
      };
      try {
        await resetNewUserPassword(payload);
        toast.success("Password Updated Successfully!");
        const loginUserData = await loginUserFn(loginPayload);
        localStorage.setItem("accessToken", loginUserData?.data?.access_token);
        localStorage.setItem("refreshToken", loginUserData?.data?.refresh_token);
        window.location.href = `${process.env.REACT_APP_FE_URL}`;
        navigate("/property-search");
      } catch (err:any) {
        console.error(err);
        toast.error((err as CustomError)?.response?.data?.message);
      } finally {
        setSubmitClicked(false);
      }

    } else{
      console.error('executeRecaptcha is undefined');
    }
  };

  useEffect(() => {
    if (watch("password") === watch("confirmPassword")) {
      clearErrors("confirmPassword");
    }
  }, [watch("password"), watch("confirmPassword")]);

  return (
    <>
      {open && (
        <Dialog
          onClose={handleClose}
          open={open}
          className={`stepsDialog loginRegister`}
          data-testid="loginModal"
        >
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>
          <div className="dialogWrapper authStyle">
          <div className="authStyle__logo flex alignCenter justifyCenter">
            <Logo />
          </div>
          <div className="authStyle__header">
            <h2>Welcome to OttoLeads!</h2>
            <p> You have been invited to {organisationName}</p>
          </div>
          <form className=" left-align">
            <Input
              type="password"
              label="Password*"
              register={register}
              name="password"
              placeholder="Enter password"
              errors={errors}
            ></Input>
            <Input
              type="password"
              label="Confirm Password*"
              register={register}
              name="confirmPassword"
              placeholder="Re-enter password"
              errors={errors}
            ></Input>
            <div className="authStyle__footer">
              <Button
                label="Create password"
                className="primary full xl"
                action={handleSubmit(onSubmit)}
                clicked={submitClicked}
                disabled={submitClicked}
              ></Button>
            </div>
          </form>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default NewUserResetPassword;
