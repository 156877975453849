import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import DateRangePickerCalendar from "../../../atoms/DateRangePickerCalendar";
import Input from "../../../atoms/Input";

const Demographics = ({ onInputBlur }: { onInputBlur: Function }) => {
  const {
    formState: { errors },
    control,
    register,
  }: any = useFormContext();
  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__full`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="demographicsFilter.inherited"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Inheritance</h5>
                  <p>Properties which are inherited.</p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Household Income ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="demographicsFilter.medianIncomeMin"
                placeholder="No Min"
                errors={errors?.["demographicsFilter"]?.["medianIncomeMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="demographicsFilter.medianIncomeMax"
                placeholder="No Max"
                errors={errors?.["demographicsFilter"]?.["medianIncomeMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Owner Age</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="numeric"
                register={register}
                name="demographicsFilter.ownerAgeMin"
                placeholder="No Min"
                errors={errors?.["demographicsFilter"]?.["ownerAgeMin"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="numeric"
                register={register}
                name="demographicsFilter.ownerAgeMax"
                placeholder="No Max"
                errors={errors?.["demographicsFilter"]?.["ownerAgeMax"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__full`}>
          <p>Divorce date</p>
          <DateRangePickerCalendar
            placeHolder="Select dates"
            control={control}
            name="demographicsFilter.divorceDate"
            errors={errors?.["demographicsFilter"]?.["divorceDate"]}
          />
        </div>
      </div>
    </>
  );
};
export default Demographics;
