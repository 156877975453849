import { Tooltip } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Announcement from "../../assets/icons/announcement";
import List from "../../assets/icons/list";
import Search from "../../assets/icons/search";
import Target from "../../assets/icons/target";
import Zap from "../../assets/icons/zap";
import {
  ROLE_PERMISSION_STATUS,
  SUBSCRITPTION_STATUS,
} from "../../constants/constants";
import {
  MEDIA_TYPES,
  MediaContext,
  MediaContextProps,
} from "../../context/MediaContext";
import { useMobileMenuDrawerContext } from "../../context/MobileMenuDrawerContext";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { getSubscriptions } from "../../organisms/billing/selectors";
import NotificationsMenu from "../notificationsMenu/NotificationsMenu";
import { getProfileInfo } from "../profileMenu/selectors";
import { NavProps } from "./interface";
import styles from "./NavLeft.module.scss";
import SearchReflation from "../../assets/icons/searchReflation";
import LeadsNav from "../../assets/icons/leadNav";
import Rocket from "../../assets/icons/rocket";
import RocketIcon from "../../assets/icons/rocketIcon";
import SearchNav from "../../assets/icons/searchNav";

const NavLeft = ({
  blackFixed,
  loggedIn,
  notificationData,
  upgradeNow,
}: NavProps) => {
  const { media } = useContext(MediaContext) as MediaContextProps;
  const { closeDrawer } = useMobileMenuDrawerContext();
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  const toolTipText =
    sunscriptions?.generalSubData &&
    !(
      profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER &&
      profileInfo?.isSeatSubscriptionActive === false
    )
      ? sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined"
        ? SUBSCRITPTION_STATUS.CardDecline
        : ""
      : profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER &&
        profileInfo?.isSeatSubscriptionActive === false
      ? SUBSCRITPTION_STATUS.DisableUser
      : profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser : SUBSCRITPTION_STATUS.NoGeneralAccess;

  const onLinkClick = (e: any) => {
    if (
      !sunscriptions?.generalSubData ||
      sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined" ||
      (profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER &&
        profileInfo?.isSeatSubscriptionActive === false)
    ) {
      e.preventDefault();
      return;
    }
    closeDrawer();
  };

  return (
    <>
      <ul
        className={` dflex alignCenter ${styles.navLeft} ${
          blackFixed ? styles.navWhite : ""
        }`}
      >
        {loggedIn ? (
          <>
            <Tooltip title={toolTipText}>
              <li>
                <NavLink
                  to="/property-search"
                  role="link"
                  className={({ isActive }) =>
                    ` ${isActive ? styles.active : ""}`
                  }
                  onClick={(e) => {
                    onLinkClick(e);
                  }}
                >
                  <span className={` ${styles.navIcon}`}>
                    <SearchNav />
                  </span>
                  Prop Search
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip title={toolTipText}>
              <li>
                <NavLink
                  to="/campaigns"
                  role="link"
                  className={({ isActive }) =>
                    ` ${isActive ? styles.active : ""}`
                  }
                  onClick={(e) => {
                    onLinkClick(e);
                  }}
                  data-testid="Campaigns"
                >
                  <span className={` ${styles.navIcon}`}>
                    <RocketIcon />
                  </span>{" "}
                  Campaigns
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip title={toolTipText}>
              <li>
                <NavLink
                  to="/leads"
                  role="link"
                  className={({ isActive }) =>
                    ` ${isActive ? styles.active : ""}`
                  }
                  onClick={(e) => {
                    onLinkClick(e);
                  }}
                >
                  <span className={` ${styles.navIcon}`}>
                    <LeadsNav />
                  </span>{" "}
                  Leads
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip title={toolTipText}>
              <li>
                <NavLink
                  to="/lists"
                  role="link"
                  className={({ isActive }) =>
                    ` ${isActive ? styles.active : ""}`
                  }
                  onClick={(e) => {
                    onLinkClick(e);
                  }}
                  data-testid="list"
                >
                  <span className={` ${styles.navIcon}`}>
                    <List />
                  </span>{" "}
                  Lists
                </NavLink>
              </li>
            </Tooltip>
            {/* <Tooltip title={`${sunscriptions?.generalSubData?'':SUBSCRITPTION_STATUS.NoGeneralAccess}`}>
            <li>
              <NavLink
                to="/leads"
                role="link"
                className={({ isActive }) =>
                  ` ${isActive ? styles.active : ""}`
                }
                onClick={(e)=>{ onLinkClick(e)}}
              >
                <span className={` ${styles.navIcon}`}>
                  <Target />
                </span>{" "}
                Leads
              </NavLink>
            </li>
            </Tooltip> */}
            {media !== MEDIA_TYPES.DESKTOP ? (
              <>
                {/* <li className={` ${styles.hideDesktop}`}>
                  <NavLink to="/buylist" role="link" className={({ isActive }) => ` ${isActive ? styles.active : ""}`} onClick={closeDrawer}>
                    <span className={` ${styles.navIcon}`}>
                      <Rocket color="#D0E3FF" />
                    </span>{" "}
                    Buy a list
                  </NavLink>
                </li> */}
                <Tooltip
                  title={
                    profileInfo?.userRoleName !==
                    PermissionListEnum.ORGANISATION_OWNER
                      ? ROLE_PERMISSION_STATUS.NoAccess
                      : toolTipText
                  }
                >
                  <li className={` ${styles.hideDesktop}`}>
                    <NavLink
                      to="/"
                      role="link"
                      className={({ isActive }) =>
                        ` ${isActive ? styles.active : ""}`
                      }
                      onClick={(e) => {
                        if (
                          !sunscriptions?.generalSubData ||
                          sunscriptions?.generalSubData?.lastPaymentStatus ===
                            "card_declined" ||
                          profileInfo?.userRoleName !==
                            PermissionListEnum.ORGANISATION_OWNER
                        ) {
                          e.preventDefault();
                          return;
                        }
                        upgradeNow();
                      }}
                    >
                      <span className={` ${styles.navIcon}`}>
                        <Zap color="#FAC515" />
                      </span>{" "}
                      Upgrade now
                    </NavLink>
                  </li>
                </Tooltip>
                <li className={` ${styles.hideDesktop}`}>
                  <NotificationsMenu />
                </li>{" "}
              </>
            ) : (
              <> </>
            )}
          </>
        ) : (
          <>
            {/* <li>
              <span role="link">
                <span className={` ${styles.navIcon}`}>
                  <Campaigns />
                </span>{" "}
                Resources <DropDownIcon />{" "}
              </span>
            </li>
            <li>
              <span role="link">
                <span className={` ${styles.navIcon}`}>
                  <Leads />
                </span>{" "}
                Pricing
              </span>
            </li> */}
          </>
        )}
      </ul>
    </>
  );
};

export default NavLeft;
