import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import Chat from "../../../../organisms/chat/Chat";
import {
  getPropertyComment,
  postPropertyComment,
  putPropertyComment,
} from "../../../../organisms/propertySearchFilter/PropertyService";
import { PropertyDetailsParentIdType } from "../../property.enum";
import { ResponseGenerator } from "../../property.saga";
import { getPropertyDetails } from "../../property.selector";

const SmartSummaryTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [addNotesLoading, setAddNotesLoading] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const handleAddMessage = async (htmlContent: string) => {
    try {
      setAddNotesLoading(true);
     await postPropertyComment(
         propertyDetails?.leadId ,{ 
          body: htmlContent, propertyId: propertyDetails.propertyId },
           PropertyDetailsParentIdType.LEAD_ID
         )
         await fetchMessages( propertyDetails?.leadId? propertyDetails?.leadId:propertyDetails?.propertyId)
      handleShowToast("Comment added successfully");
      setAddNotesLoading(false);
    } catch (error: any) {
      setAddNotesLoading(false);
      handleShowToast(
        error?.response?.data?.message || "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleEditMessage = async (id:string,htmlContent: string) => {
    try {
      setAddNotesLoading(true);
      await putPropertyComment(id, {
        body: htmlContent,
      });
      setMessages((messages: any[]) =>
        messages.map((item) => {
          if (item.id === id) {
            item.body = htmlContent;
          }
          return item;
        }))
      // await fetchMessages(propertyDetails?.leadId? propertyDetails?.leadId:propertyDetails?.propertyId);
      handleShowToast("Comment updated successfully");
      setAddNotesLoading(false);
    } catch (error: any) {
      setAddNotesLoading(false);
      handleShowToast(
        error.message || "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const fetchMessages = useCallback(
    async (id: string | number, pageNumber = 1) => {
      try {
        setIsLoading(true);
        const data = {
          index: pageNumber,
          size: 5,
        };
        const response = await getPropertyComment(
          id,
          data,
          PropertyDetailsParentIdType.LEAD_ID
        );
        
        const newMessage = response?.data?.result?.map((message: any) => ({
          ...message,
          createdAt: message?.CreatedAt || "",
          body: message?.comment || "",
          alignRight:
            (message.created_by || message.createdBy) === profileInfo.id,
          createdByName:
            (message.created_by || message.createdBy) === profileInfo.id
              ? "You"
              : "Ottoleads",
          id: message.commentId,
        }));
        console.log("response?.data?.totalCount",response?.data?.totalCount)
        setTotalCount(+(response?.data?.totalCount || 0));
        setMessages((messages) => [...(pageNumber===1? [] :messages), ...(newMessage || [])]);
      } catch (error: unknown) {
      } finally {
        setIsLoading(false);
      }
    },
    []
  );
   

  useEffect(() => {
    if (propertyDetails?.leadId) {
      fetchMessages(propertyDetails?.leadId, pageNumber);
    }
  }, [propertyDetails?.leadId, pageNumber]);

  return (
    <Chat
      chatLoading={isLoading}
      sendLoading={addNotesLoading}
      messages={messages}
      onSend={handleAddMessage}
      title="AI Smart Summary"
      totalCount={totalCount}
      onEdit={handleEditMessage}
      smartSummary
      handleNextPage={() => setPageNumber((prev) => prev + 1)}
    />
  );
};
export default SmartSummaryTab;
