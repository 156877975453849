import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import CreateOrganizationModal from "../../components/CreateOrganizationModal/CreateOrganizationModal";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { SettingHeader } from "../../components/settingHeader/SettingHeader";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { PermissionListEnum } from "../../organisms/auth/enum";
import AddPaymentModal from "../../organisms/billing/AddPaymentModal";
import { getSubscriptions } from "../../organisms/billing/selectors";
import HeroSection from "../../organisms/heroSection/HeroSection";
import { fbc, fbp, fetchIp, hashNumber } from "../../utils/utils";

const Settings = () => {
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [openCreateOrganizationModal, setOpenCreateOrganizationModal] =
    useState<boolean>(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Setting_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(
                  profileInfo?.data?.result?.organisationId
                ),
              },
            },
          ],
        };

        await FbConversionApi(payloadData);
      } catch (error) {
        console.error("Error sending payload:", error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  return (
    <>
    

      {!sunscriptions?.generalSubData && (
         profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER  ? 
         <>
        <div className="wrapper bottom-zero">
          <div className="setting-alert">
          You don't have platform access. Please contact your organisation owner.
          </div>
        </div>
      </>:<>
          <div className="wrapper bottom-zero">
            <div className="setting-alert">
              You don't have platform access. Please head to the "Billing" tab
              and hit "Upgrade Now".
            </div>
          </div>
        </>
      )}
      {sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined" && (
        <>
          <div className="wrapper bottom-zero">
            <div className="setting-alert">
              You don't have platform access because your account is overdue.
              Please{" "}
              <span role="link" onClick={() => setOpenAddPaymentModal(true)}>
                update your credit card.
              </span>
            </div>
          </div>
        </>
      )}
      {profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER &&
        profileInfo?.isSeatSubscriptionActive === false && (
          <>
            <div className="wrapper bottom-zero">
              <div className="setting-alert">
                Your account has been disabled. Try connecting with your
                organization owner or{" "}
                <span
                  role="link"
                  onClick={() => setOpenCreateOrganizationModal(true)}
                >
                  create a new organization.
                </span>
              </div>
            </div>
          </>
        )}
      <HeroSection
        title="Settings"
        desc="Manage your account preferences here."
      />
      <div className="wrapper">
        <SettingHeader></SettingHeader>
      </div>
      {openAddPaymentModal && (
        <AddPaymentModal
          handleClose={() => {
            setOpenAddPaymentModal(false);
          }}
          open={openAddPaymentModal}
          replacePaymentMethod={true}
        ></AddPaymentModal>
      )}
      {openCreateOrganizationModal && (
        <CreateOrganizationModal
          isOpen={openCreateOrganizationModal}
          handleIsOpen={(status) => setOpenCreateOrganizationModal(status)}
        ></CreateOrganizationModal>
      )}
    </>
  );
};
export default Settings;
