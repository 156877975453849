import dayjs from "dayjs";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import FallbackPropertyImage from "../../assets/images/no-property-image-found.png";
import ArrowButton, { ArrowDirection } from "../../atoms/ArrowButton";
import ImageWithLoading from "../../atoms/ImageWithLoading";
import { formartNumberAsLocalString, formatCurrency } from "../../utils/utils";
import styles from "./PropertyCard.module.scss";
import { getPropertiesList } from "./selectors";
const PropertyCard = ({
  property,
  handleMouseHover,
  handleMouseLeave,
  selectedProperty,
  handlePropertyClick,
  propertiesInView,
  setPropertiesInView,
}: {
  property: any;
  handleMouseHover: Function;
  handleMouseLeave: Function;
  handlePropertyClick: Function;
  selectedProperty: any;
  propertiesInView: any[];
  setPropertiesInView: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const settings = {
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    buttons: false,
    nextArrow: (
      <ArrowButton
        className={undefined}
        style={undefined}
        onClick={undefined}
        type={ArrowDirection.RIGHT}
      />
    ),
    prevArrow: (
      <ArrowButton
        className={undefined}
        style={undefined}
        onClick={undefined}
        type={ArrowDirection.LEFT}
      />
    ),
  };
  const elementRef = useRef(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const propertiesList = useSelector((state) => getPropertiesList(state));
  const propertiesIdList = useMemo(() => {
    return propertiesList.map((el: any) => el.propertyId);
  }, [propertiesList]);
  useEffect(() => {
    if (observerRef.current && elementRef.current) {
      observerRef.current.unobserve(elementRef.current);
    }
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (window.allFilterOpen) {
          return;
        }
        const index = propertiesInView.findIndex(
          (el) => property?.propertyId === el.propertyId
        );
        if (entry.isIntersecting) {
          if (index === -1) {
            setPropertiesInView((prev: any[]) => {
              const filtered = prev.filter((el) =>
                propertiesIdList.includes(el.propertyId)
              );
              return [...filtered, property];
            });
          }
        } else {
          if (index > -1) {
            setPropertiesInView((prev: any[]) => {
              const list = [...prev];
              const filtered = list.filter((el) =>
                propertiesIdList.includes(el.propertyId)
              );
              filtered.splice(index, 1);
              return [...filtered];
            });
          }
        }
      },
      { threshold: 0.17 }
    );

    if (elementRef.current) {
      observerRef.current.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observerRef?.current?.unobserve(elementRef.current);
      }
    };
  }, [
    propertiesInView,
    setPropertiesInView,
    property?.propertyId,
    propertiesIdList,
  ]);

  return (
    <div
      ref={elementRef}
      key={`${property?.propertyId}`}
      id={`${property?.propertyId}`}
      className={`${styles.propertyCard} ${
        property?.propertyId === selectedProperty?.propertyId
          ? styles.active
          : ""
      } `}
      onMouseEnter={() => handleMouseHover(property)}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className={` ${styles.propertyCard__card}`}>
        <figure>
          <Slider className="slick" {...settings}>
            {property?.images?.length > 0 ? (
              property?.images?.map((imageUrl: string) => (
                <div className={` ${styles.propertyCard__relative}`}>
                  <ImageWithLoading
                    src={imageUrl ?? FallbackPropertyImage}
                    alt="property"
                  ></ImageWithLoading>
                </div>
              ))
            ) : (
              <div className={` ${styles.propertyCard__relative}`}>
                <ImageWithLoading
                  src={property?.propertyImageUrl || FallbackPropertyImage}
                  alt="property"
                ></ImageWithLoading>
              </div>
            )}
          </Slider>
        </figure>
        <figcaption role="link" onClick={() => handlePropertyClick(property)}>
          <h5>{property?.address}</h5>
          <ul className="flex justifySpaceBetween">
            <li>
              <h6>
                {" "}
                {property?.bedroomCount && property?.bedroomCount !== 0
                  ? property?.bedroomCount
                  : "-"}
              </h6>
              <p>Beds</p>
            </li>
            <li>
              <h6>
                {" "}
                {property?.bathroomCount && property?.bathroomCount !== 0
                  ? property?.bathroomCount
                  : "-"}
              </h6>
              <p>Baths</p>
            </li>
            <li>
              <h6>
                {" "}
                {formartNumberAsLocalString(
                  property?.totalBuildingAreaSquareFeet
                ) ?? "-"}
              </h6>
              <p>Sq Ft</p>
            </li>
            <li>
              <h6>{`${
                property?.estimatedValue
                  ? `${formatCurrency(
                      Math.round(+property?.estimatedValue / 1000),
                      0,
                      0
                    )}${property?.estimatedValue ? "K" : ""}`
                  : "N/A"
              }`}</h6>
              <p>Est. Value</p>
            </li>
            <li>
              <h6>{property?.yearBuilt || "-"}</h6>
              <p>Built</p>
            </li>
          </ul>
          <div
            className={` ${styles.propertyCard__cardFooter} flex justifySpaceBetween`}
          >
            <p>
              Last Sale:{" "}
              {`${
                property?.lastSaleDate
                  ? dayjs(property?.lastSaleDate).format("MMM, YYYY")
                  : "N/A"
              }`}
            </p>
            <p>
              Sold Price:{" "}
              {`${formatCurrency(
                Math.round(+property?.lastSalePrice / 1000),
                0,
                0,
                "N/A"
              )}`}
              {property?.lastSalePrice ? "K" : ""}
            </p>
          </div>
        </figcaption>
      </div>
    </div>
  );
};

export default PropertyCard;
