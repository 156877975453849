export enum AdvancedFilterAccordians {
  PropertyCharacteristics = "PropertyCharacteristics",
  AuctionsPreForeclosure = "AuctionsPreForeclosure",
  Ownership = "Ownership",
  TaxLiens = "TaxLiens",
  ValuationEquity = "ValuationEquity",
  MortgageInfo = "MortgageInfo",
  CashBuyers = "CashBuyers",
  Demographics = "Demographics",
  None = "",
  QuickFilter = "QuickFilter",
}

export enum FilterValueType {
  ARRAY = "ARRAY",
  OBJECT = "OBJECT",
  MIN_MAX = "MIN_MAX",
  DATE_RANGE = "DATE_RANGE",
  RECORDING_DATE = "RECORDING_DATE",
  BOOLEAN = "BOOLEAN",
  QUICK_FILTER="QUICK_FILTER",
}
