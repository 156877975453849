import { format as formatDate } from "date-fns";
import { enUS } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import { useSelector } from "react-redux";
import Follow from "../../../../assets/icons/follow";
import TargetIcon from "../../../../assets/icons/targetIcon";
import Button from "../../../../atoms/Button";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import Loader from "../../../../atoms/Loader";
import { getSelectedLeadId } from "../../../../organisms/leadsTable/selectors";
import { PropertyDetailsParentIdType } from "../../property.enum";
import { getPropertyDetails } from "../../property.selector";
import { updatePropertyDetails } from "../../property.service";
import styles from "./FollowUpTab.module.scss";

const FollowUpTab = ({ type }: { type: PropertyDetailsParentIdType }) => {
  const [followUpDate, setFollowUpDate] = useState<Date | undefined>(undefined);
  const [displayCalender, setDisplayCalender] = useState(false);
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (
      propertyDetails &&
      Object.keys(propertyDetails).length > 0 &&
      propertyDetails?.followUpAt
    ) {
      setFollowUpDate(new Date(propertyDetails.followUpAt));
    }
  }, [propertyDetails]);

  const handleDateChange = useCallback(
    async (date: Date | null | undefined) => {
      if (date) {
        setFollowUpDate(new Date(date));
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const dateof = ` ${date.getFullYear()}-${m > 9 ? m : "0" + m}-${
          d > 9 ? d : "0" + d
        }`;
        try {
          setIsUpdating(true);
          await updatePropertyDetails({
            id:
              type === PropertyDetailsParentIdType.LEAD_ID
                ? selectedLeadId
                : propertyDetails?.propertyId,
            payload: { followUpAt: dateof ?? null },
            type,
          });
          setIsUpdating(false);
          setDisplayCalender(false);
        } catch (error) {
          setIsUpdating(false);
        }
      }else{
        try {
          setIsUpdating(true);
          await updatePropertyDetails({
            id:
              type === PropertyDetailsParentIdType.LEAD_ID
                ? selectedLeadId
                : propertyDetails?.propertyId,
            payload: { followUpAt: null },
            type,
          });
          setIsUpdating(false);
          setFollowUpDate(undefined)
          setDisplayCalender(false);
        } catch (error) {
          setIsUpdating(false);
        }
      }
    },
    [selectedLeadId, propertyDetails?.propertyId, setFollowUpDate, type]
  );

  const hanleButtonClick = () => {
    setDisplayCalender(true);
  };

  return (
    <div className="wrapper">
      <div className={` ${styles.follow} dflex alignCenter`}>
      <div className={` ${styles.follow__Placeholder} `}>
              <EmptyPlaceholder
                icon={<Follow />}
                title={
                  !followUpDate
                    ? "Let’s Close That Deal!"
                    : "Follow Up Scheduled"
                }
                description={
                  followUpDate
                    ? formatDate(followUpDate, "MMMM dd, yyyy")
                    : "It’s a fact: 75% of deals close after the follow-up. Time to take action and schedule yours now!"
                }
                bgGray={true}
                extraStyles={{ maxWidth: "450px", paddingBottom: 0,minHeight:0 }}
                blared
                button1={
                  !followUpDate ? (
                    <Button
                      prefix={<TargetIcon />}
                      className="outline borderGradient"
                      label="Set Follow up date"
                      action={hanleButtonClick}
                    ></Button>
                  ) : (
                    <Button
                      prefix={<TargetIcon />}
                      className="outline borderGradient"
                      label="Set a New Follow up date"
                      action={hanleButtonClick}
                    ></Button>
                  )
                }
                button2={
                    !followUpDate ? (
                      <></>
                      ) : (
                        <Button
                          className="outline borderGradient"
                          label="Clear"
                         action={()=>handleDateChange(null)}
                        ></Button>
                      )
                }
              />
            </div>
      <div className={`flex justifyCenter ${styles.follow__calender} ${!displayCalender ? styles.blur : '' }`}>
              {isUpdating ? (
                <Loader />
              ) : (
                <Calendar
                  months={2}
                  direction="horizontal"
                  locale={enUS}
                  date={followUpDate}
                  onChange={handleDateChange}
                  dateDisplayFormat={"MM/dd/yyyy"}
                  minDate={new Date()}
                ></Calendar>
              )}
            </div>
    
      </div>
    </div>
  );
};
export default FollowUpTab;
