import { useState } from "react";
import property from "../assets/images/map-img.jpeg";

const ImageWithLoading = ({
  src,
  alt,
  onClick,
}: {
  src: string;
  alt: string;
  onClick?: any;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      {isLoading && (
        <div className="image-loading">
          <img src={property} alt="property" />
          <div className="image-loading__loader">
            <div className="dot-pulse"></div>
          </div>
        </div>
      )}
      <img
        src={src}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
        alt={alt}
        onClick={() => onClick && onClick()}
      ></img>
    </>
  );
};
export default ImageWithLoading;
