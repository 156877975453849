import React from "react"
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { useForm } from "react-hook-form";
import { StartCampaignProps } from "./interface";
import { startCampaignFormSchema } from "./validation";
import styles from "./CreateCampaign.module.scss";
import Flag from "../../assets/icons/flag";
import CustomDatePicker from "../../atoms/CustomDatePicker";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  campaignsVoiceList,
  getCampaignLeadList,
  getCampaignLoadingState,
  getLoadingVoiceList,
} from "./selectors";
import ArrowLeft from "../../assets/icons/arrowLeft";
import Rocket from "../../assets/icons/rocket";
import { CampaignSteps } from "./enum";
import Loader from "../../atoms/Loader";
import Zap from "../../assets/icons/zap";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import UpgradeModal from "../upgradeModal/UpgradeModal";
import { getSubscriptions } from "../billing/selectors";
import { UpgradeModalParentType } from "../upgradeModal/enum";
import InfoCircle from "../../assets/icons/infoCircle";
import { MAX_ATTEMPTS_OPTIONS, DPN } from "./constants";
import { Tooltip } from "@mui/material";
import { formartNumberAsLocalString } from "../../utils/utils";
import CustomModal from "../../components/customModal/CustomModal";
import CampaignCallBackNo from "./CampaignCallBackNo";
const StartCampaign = ({
  onSubmit,
  stepData,
  setStep,
  selectedList,
  selectedListNames,
  campaignCost
}: StartCampaignProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(startCampaignFormSchema),
    defaultValues: { ...stepData },
  });
  const [voiceListOptions, setVoiceListOptions] = useState([]);
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  // const [campaignCost, setCampaignCost] = useState(0);
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState<any>(null);
  const voiceList: any = useSelector((state: any) => campaignsVoiceList(state));
  const campaignLoadingState = useSelector((state: any) =>
    getCampaignLoadingState(state)
  );
  const loadingVoiceList = useSelector((state) => getLoadingVoiceList(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const subscriptions = useSelector((state) => getSubscriptions(state));
  const campaignLeadList = useSelector((state) => getCampaignLeadList(state));
  const [showCallBackModal, setShowCallBackModal] = useState(false);
  const [showCallBackNo, setShowCallBackNo] = useState("");
  const handleCallBackPhoneModal =()=>{
    setShowCallBackModal(!showCallBackModal)
  }
  
  useEffect(()=>{
   setValue("callbackPhone",showCallBackNo)
  },[showCallBackNo])

  useEffect(() => {
    if (voiceList?.length) {
      const options = voiceList?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setVoiceListOptions(options);
    }
  }, [voiceList]);

  useEffect(()=>{
    if(profileInfo?.organisation?.callbackPhone){
      setShowCallBackNo(profileInfo?.organisation?.callbackPhone)
    }
  },[profileInfo])

  useEffect(() => {
    if (
      watch("campaignName").length > 0 &&
      watch("campaignName").trim().length === 0
    ) {
      setValue("campaignName", "");
    }
  }, [watch("campaignName")]);

  useEffect(() => {
    if (watch("optimizeCampaign")) {
      setValue("maxAttemptPerNumber", "");
      setValue("dialsPerNumber", DPN[0]?.value?.toString() ?? "");
    }
  }, [watch("optimizeCampaign")]);

  // useEffect(() => {
  //   if (Array.isArray(selectedList)) {
  //     let totalCost = 0;
  //     const selected = campaignLeadList.filter((lead: any) =>
  //       selectedList.includes(lead?.id)
  //     );

  //     selected.forEach((lead: any) => {
  //       totalCost = totalCost + lead?.propertyCount ?? 0;
  //     });
  //     setCampaignCost(totalCost);
  //   }
  // }, [selectedList, campaignLeadList]);

  useEffect(() => {
    if (selectedListNames || selectedListNames[0]) {
      setValue("campaignName", selectedListNames[0]);
    }
  }, [selectedListNames, setValue]);

  return (
    <>
      {loadingVoiceList ? (
        <div className={`loader-main`}>
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.createCampaign}`}>
          <div className={`headerIcon ${styles.headerIcon}`}>
            <span className={`${styles.flag}`}>
              <Flag />
            </span>
          </div>
          <div className={`${styles.createCampaign__header}`}>
            <h3>
              Finalize your campaign <span>(Step 2 of 2)</span>
            </h3>
            <p>Provide your campaign details below</p>
          </div>

          {/* {(!sunscriptions.creditSubData ||
            campaignCost > +profileInfo?.organisation?.availableCredit) && (
            <>
              <div
                className={`flex justifySpaceBetween alignCenter ${styles.creditsUpgrade}`}
              >
                <div className="flex alignCenter">
                  <span className={`animated-svg `}>
                    <InfoCircle />
                  </span>
                  <p>
                    <strong>Insufficient AI calling credits</strong>
                    <br />
                    You only have {
                      profileInfo?.organisation?.availableCredit
                    }{" "}
                    credits remaining.
                  </p>
                </div>
                <div data-testid="upgradeNow">
                {campaignCost >= 10000 ? (
                  <Tooltip title="Your list records should be less than or equal to 10k" placement="top">
                    <div>
                  <Button
                    label="Upgrade now"
                    prefix={<Zap />}
                    className="primary animated"
                    action={() => {
                      setOpenPlanUpgradeModal(true);
                      setUpgradSubscriptionProduct(
                        sunscriptions?.creditSubData
                      );
                    }}
                    disabled={
                      campaignCost > +profileInfo?.organisation?.availableCredit
                    }
                  />
                  </div>
                  </Tooltip>)
                  :<Button
                  label="Upgrade now"
                  prefix={<Zap />}
                  className="primary animated"
                  action={() => {
                    setOpenPlanUpgradeModal(true);
                    setUpgradSubscriptionProduct(
                      sunscriptions?.creditSubData
                    );
                  }}
                  
                />
                  }
                </div>
              </div>
            </>
          )} */}

          <Input
            type="text"
            label="Campaign Name*"
            register={register}
            name="campaignName"
            placeholder="Give your campaign a friendly name"
            errors={errors}
          ></Input>
          {/* <DropDown
        options={voiceListOptions}
        label="Voice*"
        control={control}
        name="voice"
        errors={errors}
        placeholder="Voice"
        defaultStartAdornment={
          <InputAdornment position="start">
            <PlaySquare />
          </InputAdornment>
        }
        hint="Select your desired voice model."
      ></DropDown> */}
          <CustomDatePicker
            label="Start date*"
            control={control}
            name="startDate"
            errors={errors}
          ></CustomDatePicker>
          {/* {!watch("optimizeCampaign") && (
            <DropDown
              options={DPN}
              label=" Maximum dials per number"
              control={control}
              name="dialsPerNumber"
              errors={errors}
              placeholder="Select the maximum number of dials"
              disabled={watch("optimizeCampaign")}
            ></DropDown>
          )} */}
          <div className={`${styles.credits}`}>
            This campaign will cost <strong>{formartNumberAsLocalString(campaignCost)}</strong> credits.
          </div>
          <div className={`${styles.credits}`}>
          Your currect callback phone number is <strong>{showCallBackNo}</strong>. If you want to change then {" "}<span role="link" onClick={()=> handleCallBackPhoneModal()}>click here</span>.
          </div>

          {/* <div className={`${styles.switch} flex alignCenter`}>
            <CustomSwitch
              control={control}
              name="optimizeCampaign"
              defaultChecked={true}
            />
            <div>
              <h5>Optimize for best results</h5>
              <p>
                The algorithm will automatically optimize your campaign for the
                best results.
              </p>
            </div>
          </div> */}
          {/* hide and show on switch */}
          {/* {!watch("optimizeCampaign") && (
            <div>
              <DropDown
                options={MAX_ATTEMPTS_OPTIONS}
                label="Maximum attempts per number*"
                control={control}
                name="maxAttemptPerNumber"
                errors={errors}
                placeholder="Select the maximum number of attempts"
                hint="We recommend 7 attempts per number for optimal results. DNC, disconnected numbers, and numbers with other undesirable results are excluded on each subsequent list of phone numbers to attempt."
              ></DropDown> */}
              {/* <DropDown
            options={DUMMY_OPTIONS}
            label="Attempt frequency*"
            control={control}
            name="attemptFrequency"
            errors={errors}
            placeholder="Select how long to wait before redialing a number"
            hint="We recommend waiting 1 day between each attempt."
          ></DropDown> */}
            {/* </div>
          )} */}

          <div className={`${styles.createCampaign__action}`}>
            <div className={`${styles.mainCol}`}>
              <div className={`${styles.mainCol__half}`}>
                <Button
                  label={"Go back"}
                  prefix={<ArrowLeft />}
                  className="outline xl full"
                  action={() => setStep(CampaignSteps.SelectList)}
                ></Button>
              </div>
              <div className={`${styles.mainCol__half} ${styles.step2Button}`}>
                {campaignCost >= 10000 ? (
                  
                  <Tooltip title="Your list records should be less than or equal to 10k" placement="top-start">
                    <div>
                    <Button
                      label={"Launch campaign"}
                      prefix={<Rocket />}
                      className="primary xl full animated"
                      action={handleSubmit(onSubmit)}
                      clicked={campaignLoadingState}
                      disabled={
                        campaignLoadingState ||
                        campaignCost >
                          +profileInfo?.organisation?.availableCredit
                      }
                    ></Button>
                    </div>
                  </Tooltip>
                ) : 
                subscriptions?.creditSubData?.isPaused  ?(<Tooltip title="You can't access this because your subscription is paused." placement="top-start">
                    <div>
                    <Button
                      label={"Launch campaign"}
                      prefix={<Rocket />}
                      className="primary xl full animated"
                      action={handleSubmit(onSubmit)}
                      clicked={campaignLoadingState}
                      disabled={
                        subscriptions?.creditSubData?.isPaused
                      }
                    ></Button>
                    </div>
                  </Tooltip> ): 
                (<Button
                label={"Launch campaign"}
                prefix={<Rocket />}
                className="primary xl full animated"
                action={handleSubmit(onSubmit)}
                clicked={campaignLoadingState}
                disabled={campaignLoadingState}
              ></Button>)
              }
              </div>
            </div>
          </div>
        </div>

       
      )}
      {<CustomModal customClass="contactInfoModal" open={showCallBackModal} handleClose={
      handleCallBackPhoneModal}>
       <CampaignCallBackNo showCallBackNo={showCallBackNo} setShowCallBackNo= { setShowCallBackNo} handleCallBackPhoneModal={handleCallBackPhoneModal}></CampaignCallBackNo>
        </CustomModal>}

      <UpgradeModal
        handleClose={() => {
          setOpenPlanUpgradeModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openPlanUpgradeModal}
        product={upgradSubscriptionProduct}
        parentType={UpgradeModalParentType.Camapign}
        minCreditCount={campaignCost}
      ></UpgradeModal>
    </>
  );
};
export default StartCampaign;
