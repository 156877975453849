import FileHeartIcon from "../../assets/icons/fileHeartIcon";
import Button from "../../atoms/Button";
import SearchBar from "../../atoms/SearchBar";
import HeroSection from "../../organisms/heroSection/HeroSection";
import ListsTable from "../../organisms/listsTable/ListsTable";
import UploadList from "../../organisms/uploadList/UploadList";
import styles from "./MyLists.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getNewListUploaded,
  getOpenImportFileModal,
} from "../../organisms/uploadList/selectors";
import {
  setNewListUploaded,
  toggleImportFileModal,
} from "../../organisms/uploadList/reducer";
import { SortDirection } from "../../molecules/table/enum";
import { resetMyListsTableState } from "../../organisms/listsTable/reducer";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { fbc, fbp, fetchIp, hashNumber, hashPhoneNumber, hashString } from "../../utils/utils";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { Tooltip } from "@mui/material";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

const MyLists = () => {
  const openUploadListModal = useSelector((state) =>
    getOpenImportFileModal(state)
  );
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<{
    searchText: string;
    pageNumber: number;
    limit: number;
    sortDirection: string;
    sortKey: string;
  }>({
    searchText: "",
    pageNumber: 1,
    limit: 20,
    sortDirection: SortDirection.DESC,
    sortKey: "createdAt",
  });

  const newListAdded = useSelector((state) => getNewListUploaded(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, searchText: value, pageNumber: 1 }));
  };
  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
  };
  const dispatchToggleImportFileModalAction = (state: boolean) => {
    dispatch(toggleImportFileModal(state));
  };

  const handleSorting = (sortKey: string | undefined) => {
    if (sortKey) {
      setFilter((prev) => ({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        sortDirection:
          prev.sortKey === sortKey
            ? prev.sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
            : SortDirection.DESC,
        sortKey: sortKey,
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (newListAdded) {
      setFilter({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        sortDirection: SortDirection.DESC,
        sortKey: "createdAt",
      });
      dispatch(setNewListUploaded(false));
    }
  }, [newListAdded]);

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "List_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(profileData?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileData]);

  return (
    <>
      <div className={` ${styles.myList}`}>
        {/* <Header loggedIn></Header> */}
        <HeroSection
          title="Lists"
          desc="Manage your uploaded or purchased lists."
        >

          <Tooltip
            title={
              profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""
            }
          >
            <div>
              {profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? (
                <Button
                  label="Import List"
                  className="primary"
                  prefix={<FileHeartIcon />}
                  disabled={profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
                />
              ) : (
                <Button
                  label="Import List"
                  className="primary"
                  prefix={<FileHeartIcon />}
                  action={() => dispatchToggleImportFileModalAction(true)}
                />
              )}
            </div>
          </Tooltip>
        </HeroSection>
        <div className="wrapper">
          <SearchBar
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              if (
                e.target.value.length > 0 &&
                e.target.value.trim().length === 0
              ) {
                value = "";
              }
              if (filter.searchText !== value) {
                setSearchText(value);
              }
            }}
            searchText={filter.searchText}
          ></SearchBar>
          <div className={` ${styles.myList__table}`}>
            <ListsTable
              handleSorting={handleSorting}
              filter={filter}
              handleNextPage={handleNextPage}
              handleClearSearch={() => setSearchText("")}
              toggleImportFileModal={dispatchToggleImportFileModalAction}
            ></ListsTable>
          </div>
        </div>
      </div>
      {openUploadListModal && (
        <UploadList
          open={openUploadListModal}
          closeModal={() => dispatchToggleImportFileModalAction(false)}
        ></UploadList>
      )}
    </>
  );
};
export default MyLists;
