import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./assets/global.scss";
import { fetchProfileInfo } from "./components/profileMenu/sagaActions";
import LoginWrapper from "./organisms/auth/LogginWrapper";
import BuyList from "./pages/buylist/BuyList";
import Campaigns from "./pages/campaigns/Campaigns";
import CampaignDetail from "./pages/detail/[id]";
import Home from "./pages/home/Home";
import Leads from "./pages/leads/Leads";
import MyLists from "./pages/mylists/MyLists";
import PropertyDetails from "./pages/property/PropertyDetails";
import PropertySearch from "./pages/propertySearch/PropertySearch";
import Page404 from "./pages/404/404";
import Header from "./components/header/Header";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Onboarding from "./pages/onboarding/Onboarding";
import Settings from "./pages/settings/Settings";
import OnboardingWrapper from "./organisms/auth/OnboardingWrapper";
import Footer from "./components/footer/Footer";
import Verification from "./pages/verification/Verification";
import GeneralAccessWrapper from "./organisms/auth/GeneralAccessWrapper";
import { refreshSession } from "./services/cognitoService";
import { setProfileInfoLoading, setSessionLoading } from "./components/profileMenu/reducer";
import { getSessionLoading } from "./components/profileMenu/selectors";
import LoggedOutWrapper from "./organisms/auth/LoggedOutWrapper";
import GlobalLoader from "./components/globalLoader/GlobalLoader";
import { setAuthGuardToken } from "./organisms/authroute/action";
import interceptor from "./Interceptors/Interceptors";
import VerifyEmail from "./organisms/auth/VerifyEmail";
import NewPassword from "./organisms/resetPassword/NewPassword";
import Cookies from "./organisms/policies/cookies";
import PrivacyPolicy from "./organisms/policies/privacyPolicy";
import TermsOfUse from "./organisms/policies/termsOfUse";
import Thankyou from "./pages/thankyou/Thankyou";
import LearnMore from "./pages/learnmore/LearnMore";
import SatisfactionGuarantee from "./organisms/policies/SatisfactionGuarantee";
import CreativeCalculatorPage from "./organisms/policies/CreativeCalculatorPage";
import { defaultLogout } from "./services/auth";

let timerId: NodeJS.Timer;
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "");

const Routing = () => {
  const dispatch = useDispatch();
  const sessionLoading = useSelector((state) => getSessionLoading(state));

  function replaceToken() {
    timerId = setTimeout(async () => {
      refreshSession();
    }, 55 * 60000);
  }
  const handleUpdateGuardTokenTime = () => {
    dispatch(setAuthGuardToken(true));
  };
  const fetchDetail = () => {
    handleUpdateGuardTokenTime();

    dispatch(fetchProfileInfo({}));
  };

  const startLoader = () => {
    dispatch(setSessionLoading(true));
  };

  const stopLoader = () => {
    dispatch(setSessionLoading(false));
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")  ) {
      if (window.location.pathname.includes("reset-password")) {
        defaultLogout();
        stopLoader()
      }else{     
         refreshSession(fetchDetail, startLoader, stopLoader);
      replaceToken();}
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId as any);
      }
    };
  }, []);

  useEffect(() => {
    interceptor();
  }, []);
  return (
    <BrowserRouter>
      {sessionLoading ? (
        <GlobalLoader></GlobalLoader>
      ) : (
        <>
          <Header />
          <div className="page-layout" id="page-layout">
            <Routes>
              <Route path="*" element={<Page404></Page404>}></Route>
              <Route
                path="/satisfaction-guarantee"
                element={
                    <SatisfactionGuarantee></SatisfactionGuarantee>
                }
              ></Route>
               <Route path="/creative-calculator" element={<CreativeCalculatorPage />} />
               <Route path="/reset-password" element={
                    <Home></Home>
                  }></Route>
              <Route
                path="/"
                element={
                  <LoggedOutWrapper>
                    <Home></Home>
                  </LoggedOutWrapper>
                }
              ></Route>
              <Route path="/verification" element={<Verification />}></Route>

              <Route
                path="/onboarding"
                element={
                  <LoginWrapper>
                    {" "}
                    <Elements stripe={stripePromise} options={{}}>
                      <Onboarding/>
                    </Elements>
                  </LoginWrapper>
                }
              ></Route>

              <Route
                path="/campaigns"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <Campaigns />{" "}
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route
                path="/lists"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <MyLists />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route
                path="/leads"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <Leads />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route
                path="/buylist"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <BuyList />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              {/* <Route
                path="/properties/:id"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <PropertyDetails />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route> */}
              <Route
                path="/property-search"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <PropertySearch />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route
                path="/campaigns/:id"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <GeneralAccessWrapper>
                        <CampaignDetail />
                      </GeneralAccessWrapper>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route
                path="/settings"
                element={
                  <LoginWrapper>
                    <OnboardingWrapper>
                      <Elements stripe={stripePromise} options={{}}>
                        <Settings></Settings>
                      </Elements>
                    </OnboardingWrapper>
                  </LoginWrapper>
                }
              ></Route>
              <Route path="/verify-email" element={<VerifyEmail />}></Route>
              <Route path="/forgot-password" element={<NewPassword />}></Route>
            
             
              <Route
                path="/policies/cookies"
                element={
                  <Elements stripe={stripePromise} options={{}}>
                    <Cookies></Cookies>
                  </Elements>
                }
              ></Route>
              <Route
                path="/policies/privacy-policy"
                element={
                  <Elements stripe={stripePromise} options={{}}>
                    <PrivacyPolicy></PrivacyPolicy>
                  </Elements>
                }
              ></Route>
              <Route
                path="/policies/terms-of-use"
                element={
                  <Elements stripe={stripePromise} options={{}}>
                    <TermsOfUse></TermsOfUse>
                  </Elements>
                }
              ></Route>

              <Route path="/thank-you" element={<Thankyou />} />

              {/* <Route path="/learn-more" element={<LearnMore />} /> */}
            </Routes>
          </div>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export default Routing;
