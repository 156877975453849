import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Check from "../../assets/icons/check";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import ApproveConfirmation from "../../components/approveConfirmation/ApproveConfirmation";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { setPendingUserList } from "../../pages/myTeam/reducer";
import { fetchActiveUsersList } from "../../pages/myTeam/sagaActions";
import { getPendingUserList } from "../../pages/myTeam/selectors";
import { PermissionListEnum } from "../auth/enum";
import {
  approveInvite,
  declineInvite,
  disableUserSeat,
  reactivateUserSeat,
  resendInvite,
} from "../pendingUsers/service";
import { MANAGE_TEAM_ACTION_MODAL_DATA } from "./constants";
import { ManageTeamActionType } from "./enum";

const ManageTeamActions = ({ activeUser }: { activeUser: any }) => {
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [declineLoadingIndex, setDeclineLoadingIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const dispatch = useDispatch();
  const pendingList = useSelector((state) => getPendingUserList(state));
  const [actionType, setActionType] = useState(ManageTeamActionType.NONE);

  const approveUserInvite = async (id: any) => {
    setLoading(true);
    try {
      const result = await approveInvite(id);
      console.log(result);
      displayToast("Invite approved successfully!", ToastType.success);
      const updatedList = pendingList?.filter((el: any) => el.id !== id);
      dispatch(setPendingUserList([...updatedList]));
      dispatch(fetchActiveUsersList({}));
      setActionType(ManageTeamActionType.NONE);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      displayToast(err?.response?.data?.message, ToastType.error);
    }
  };
  const declineUserInvite = async () => {
    setDeclineLoadingIndex(true);
    try {
      await declineInvite(activeUser?.id);
      displayToast("Invite declined successfully!", ToastType.success);

      const updatedList = pendingList?.filter(
        (el: any) => el.id !== activeUser?.id
      );
      dispatch(setPendingUserList([...updatedList]));
      dispatch(fetchActiveUsersList({}));
      setDeclineLoadingIndex(false);
      setActionType(ManageTeamActionType.NONE);
    } catch (err: any) {
      console.log(err);
      setDeclineLoadingIndex(false);
      displayToast(err?.message, ToastType.error);
    }
  };

  const reactivateUser = async (id: any) => {
    setLoading(true);
    try {
      const result = await reactivateUserSeat(id);
      dispatch(fetchActiveUsersList({}));
      displayToast("User activated successfully!", ToastType.success);
      setActionType(ManageTeamActionType.NONE);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      displayToast(err?.message, ToastType.error);
    }
  };

  const disableUser = async () => {
    setDeclineLoadingIndex(true);
    try {
      await disableUserSeat(activeUser?.id);
      dispatch(fetchActiveUsersList({}));
      displayToast("User disabled successfully!", ToastType.success);
      setDeclineLoadingIndex(false);
      setActionType(ManageTeamActionType.NONE);
    } catch (err: any) {
      console.log(err);
      setDeclineLoadingIndex(false);
      displayToast(err?.message, ToastType.error);
    }
  };

  const resendUserInvite = async (id: any) => {
    setLoadingIndex(id);
    try {
      const result = await resendInvite(id);
      console.log(result);
      displayToast("Invite sent successfully!", ToastType.success);
      setLoadingIndex(-1);
    } catch (err) {
      console.log(err);
      setLoadingIndex(-1);
    }
  };

  const displayToast = (message: string, type: ToastType) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  return (
    <div>
      {activeUser?.isPassUpdated === false &&
         (
          <Tooltip
            title={
              profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""
            }
          >
            <div>
              <Button
                key={activeUser?.id}
                className="primary"
                label="Resend Invite"
                action={() => resendUserInvite(activeUser?.inviteId)}
                disabled={
                  profileInfo?.userRoleName !==
                    PermissionListEnum.ORGANISATION_OWNER ||
                  loadingIndex === activeUser?.inviteId
                }
                clicked={loadingIndex === activeUser?.inviteId}
              ></Button>
            </div>
          </Tooltip>
        )}

      {activeUser?.status === "PENDING" && (
        <div className="flex alignCenter gap-8">
          <Tooltip
            title={
              profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""
            }
          >
            <Button
              className="primary"
              prefix={<Check />}
              label="Approve"
              action={() => {
                setActionType(ManageTeamActionType.APPROVE);
              }}
              disabled={
                profileInfo?.userRoleName !==
                PermissionListEnum.ORGANISATION_OWNER
              }
            ></Button>
          </Tooltip>

          <Tooltip
            title={
              profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""
            }
          >
            <Button
              className="red"
              prefix={<Close />}
              label="Decline"
              action={() => {
                setActionType(ManageTeamActionType.DECLINE);
              }}
              disabled={
                profileInfo?.userRoleName !==
                PermissionListEnum.ORGANISATION_OWNER
              }
            ></Button>
          </Tooltip>
        </div>
      )}
      {activeUser?.isSeatSubscriptionActive === false&& activeUser?.isPassUpdated && (
        <Tooltip
          title={
            profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER || profileInfo?.email === activeUser?.email
              ? ROLE_PERMISSION_STATUS.NoAccess
              : ""
          }
        >
          <div>
          
          <Button
            className="primary"
            prefix={<Check />}
            label="Enable"
            action={() => {
              setActionType(ManageTeamActionType.ENABLE);
            }}
            disabled={
              profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER || profileInfo?.email === activeUser?.email
            }
            ></Button>
            </div>
        </Tooltip>
      )}

      {activeUser?.isSeatSubscriptionActive && activeUser?.isPassUpdated && (
        <Tooltip
          title={
            profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER || profileInfo?.email === activeUser?.email
              ? ROLE_PERMISSION_STATUS.NoAccess
              : ""
          }
        >
          <div>

          <Button
            className="red"
            prefix={<Close />}
            label="Disable"
            action={() => {
              setActionType(ManageTeamActionType.DISABLE);
            }}
            disabled={
              profileInfo?.userRoleName !==
              PermissionListEnum.ORGANISATION_OWNER || profileInfo?.email === activeUser?.email
            }
            ></Button>
            </div>

        </Tooltip>
      )}

      {(actionType === ManageTeamActionType.DISABLE ||
        actionType === ManageTeamActionType.DECLINE) && (
        <ConfirmationModal
          isOpen={
            actionType === ManageTeamActionType.DISABLE ||
            actionType === ManageTeamActionType.DECLINE
          }
          setIsOpen={() => {
            setActionType(ManageTeamActionType.NONE);
          }}
          buttonComponent={
            <Button
              label="Yes, Disable"
              className="red xl full "
              action={
                actionType === ManageTeamActionType.DECLINE
                  ? declineUserInvite
                  : disableUser
              }
              disabled={declineLoadingIndex}
              clicked={declineLoadingIndex}
            />
          }
        >
          <h2>{MANAGE_TEAM_ACTION_MODAL_DATA[actionType].header}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: MANAGE_TEAM_ACTION_MODAL_DATA[actionType].content,
            }}
          ></p>
        </ConfirmationModal>
      )}

      {(actionType === ManageTeamActionType.APPROVE ||
        actionType === ManageTeamActionType.ENABLE) &&
        activeUser && (
          <ApproveConfirmation
            open={
              actionType === ManageTeamActionType.APPROVE ||
              actionType === ManageTeamActionType.ENABLE
            }
            handleClose={() => setActionType(ManageTeamActionType.NONE)}
            onSubmit={
              actionType === ManageTeamActionType.ENABLE
                ? reactivateUser
                : approveUserInvite
            }
            isPaidSeat={
              activeUser?.isPaidSeat || activeUser?.seatSubscriptionId === "-"
            }
            userId={activeUser?.id || ""}
            loading={loading}
          ></ApproveConfirmation>
        )}
    </div>
  );
};

export default ManageTeamActions;
