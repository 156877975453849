import { FilterValueType } from "./enum";
import {
  AdvancedFilterCountByType,
  FilterMapType,
  FilterMapValueType,
} from "./interface";

export const MARKET_STATUS_OPTION = [
  { value: "OFF_MARKET", label: "Off Market" },
  { value: "ON_MARKET", label: "On Market" },
];

export const MARKET_STATUS_OPTION_MAP: any = {
  ANY: null,
  OFF_MARKET: { off_market: false },
  FOR_SALE: { on_market: true },
  PENDING: { mls_pending: true },
  CANCELED: { mls_cancelled: true },
};

export const PROPERTY_TYPE_OPTION = [
  { value: "SINGLE", label: "Single Family " },
  { value: "MULTI", label: "Multi Family" },
  { value: "CONDOMINIUM", label: "Condominium" },
  { value: "MOBILE", label: "Mobile Home" },
  { value: "LAND", label: "Land" },
];

export const BEDS_BATHS_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const SQUARE_FEET_OPTIONS = [
  { value: 500, label: "500" },
  { value: 750, label: "750" },
  { value: 1000, label: "1,000" },
  { value: 1250, label: "1,250" },
  { value: 1500, label: "1,500" },
  { value: 1750, label: " 1,750" },
  { value: 2000, label: "2,000" },
  { value: 2250, label: "2,250" },
  { value: 2500, label: "2,500" },
  { value: 2750, label: "2,750" },
  { value: 3000, label: "3,000" },
  { value: 3500, label: "3,500" },
  { value: 4000, label: "4,000" },
  { value: 5000, label: "5,000" },
  { value: 7500, label: "7,500" },
];
const ACRE_IN_SQFT = 43560;
export const LOT_SIZE_OPTIONS = [
  { value: 1000, label: "1,000 sqft" },
  { value: 2000, label: "2,000 sqft" },
  { value: 3000, label: "3,000 sqft" },
  { value: 4000, label: "4,000 sqft" },
  { value: 5000, label: "5,000 sqft" },
  { value: Math.floor(43560 / 4), label: "1/4 acre" },
  { value: ACRE_IN_SQFT, label: "1 acre" },
  { value: 1.5 * ACRE_IN_SQFT, label: "1.5 acres" },
  { value: 2 * ACRE_IN_SQFT, label: "2 acres" },
  { value: 5 * ACRE_IN_SQFT, label: "5 acres" },
  { value: 10 * ACRE_IN_SQFT, label: "10 acres" },
  { value: 20 * ACRE_IN_SQFT, label: "20 acres" },
  { value: 50 * ACRE_IN_SQFT, label: "50 acres" },
  { value: 100 * ACRE_IN_SQFT, label: "100 acres" },
];

export const HOME_FEATURES_OPTIONS = [
  { label: "Attic ", value: "ATTIC" },
  { label: "Basement ", value: "BASEMENT" },
  { label: "Breezeway ", value: "BREEZEWAY" },
  { label: "Carport ", value: "CARPORT" },
  { label: "Deck ", value: "DECK" },
  { label: "Balcony ", value: "BALCONY" },
  { label: "Fire Sprinklers ", value: "FIRE_SPRINKLERS" },
  { label: "Garage ", value: "GARAGE" },
  { label: "Patio ", value: "PATIO" },
  { label: "Pool ", value: "POOL" },
  { label: "RV Parking ", value: "RV_PARKING" },
];

export const HOME_FEATURES_OPTIONS_MAP: any = {
  ATTIC: {
    attic: true,
  },
  BASEMENT: {
    basement: true,
  },
  BREEZEWAY: {
    breezeway: true,
  },
  CARPORT: {
    carport: true,
  },
  DECK: {
    deck: true,
  },
  BALCONY: {
    feature_balcony: true,
  },
  FIRE_SPRINKLERS: {
    fire_sprinklers: true,
  },
  GARAGE: {
    garage: true,
  },
  PATIO: {
    patio: true,
  },
  POOL: {
    pool: true,
  },
  RV_PARKING: {
    rv_parking: true,
  },
};
export const CLASSIFICATIONS_OPTIONS = [
  {
    label: "Residential",
    value: "RESIDENTIAL",
  },
  {
    label: "Commercial ",
    value: "COMMERCIAL",
  },
  {
    label: "Office",
    value: "OFFICE",
  },
  {
    label: "Recreational",
    value: "RECREATIONAL",
  },
  {
    label: "Industrial",
    value: "INDUSTRIAL",
  },
  {
    label: "Agricultural",
    value: "AGRICULTURAL",
  },
  {
    label: "Vacant Land",
    value: "VACANT_LAND",
  },
  {
    label: "Exempt",
    value: "EXEMPT",
  },
  {
    label: "Miscellaneous",
    value: "MISCELLANEOUS",
  },
];

export const CLASSIFICATIONS_OPTIONS_MAP: any = {
  RESIDENTIAL: {
    property_use_code: [
      181, 357, 358, 359, 360, 361, 362, 363, 364, 366, 367, 368, 369, 370, 371,
      372, 373, 375, 376, 377, 378, 379, 380, 381, 382, 383, 384, 385, 386, 387,
      388, 389, 390, 416, 421, 447, 452, 461,
    ],
  },
  COMMERCIAL: {
    property_use_code: [
      124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138,
      139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153,
      154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168,
      169, 172, 173, 175, 176, 177, 178, 179, 180, 182, 183, 185, 186, 187, 188,
      189, 190, 191, 192, 193, 194, 412, 449, 458, 459, 464,
    ],
  },
  OFFICE: {
    property_use_code: [135, 136, 139, 169, 170, 171, 176, 177, 184, 301],
  },
  RECREATIONAL: {
    property_use_code: [
      258, 259, 260, 261, 262, 263, 264, 266, 267, 270, 273, 285, 287, 288, 290,
      292, 293, 296, 316, 317, 322, 323, 327, 331, 332, 334, 336, 337, 346, 349,
      350, 355,
    ],
  },
  INDUSTRIAL: {
    property_use_code: [
      195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209,
      210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224,
      225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239,
      240, 243, 450,
    ],
  },
  AGRICULTURAL: {
    property_use_code: [
      101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115,
      116, 117, 118, 119, 120, 121, 122, 123, 392, 413, 446,
    ],
  },
  VACANT_LAND: {
    property_use_code: [
      389, 391, 392, 393, 394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404,
      406, 409, 453, 462,
    ],
  },
  EXEMPT: {
    property_use_code: [
      268, 269, 271, 272, 275, 277, 278, 282, 283, 284, 286, 288, 293, 294, 295,
      298, 302, 303, 304, 305, 306, 307, 308, 309, 310, 314, 315, 318, 319, 320,
      321, 324, 325, 326, 328, 329, 330, 333, 335, 336, 338, 339, 342, 344, 345,
      356, 354, 402,
    ],
  },
  OTHER: {
    property_use_code: [
      0, 241, 242, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255,
      256, 257, 409, 410, 411, 415, 417, 418, 419, 420, 422, 423, 424, 425, 426,
      427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441,
      442, 443, 444, 445, 447, 448, 451, 454, 455, 456, 457, 460, 463,
    ],
  },
};

export const AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS = [
  {
    label: "Notice of Default ",
    value: "NOTICE_OF_DEFAULT",
  },
  {
    label: "Notice of Lis Pendens",
    value: "NOTICE_OF_LIS_PENDENS",
  },
  { label: "Notice of Trustee's Sale", value: "NOTICE_OF_SALE" },
];

export const AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP: any = {
  NOTICE_OF_DEFAULT_LIS_PENDENS: { notice_type: ["NOD", "NOL"] },
  NOTICE_OF_TRUSTEES_SALE: { notice_type: ["NTS"] },
};

export const AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS = [
  { label: "Any", value: null },
  { label: "Up to a month ago ", value: { recordingDate: "1_MONTH" } },
  { label: "Up to 2 months ago ", value: { recordingDate: "2_MONTH" } },
  { label: "Up to 3 months ago ", value: { recordingDate: "3_MONTH" } },
  { label: "Up to 6 months ago ", value: { recordingDate: "6_MONTH" } },
  { label: "Up to a year ago ", value: { recordingDate: "1_YEAR" } },
];

export const OWNER_TYPE_OPTIONS = [
  { label: "Individual", value: "INDIVIDUAL" },
  { label: "Corporate", value: "CORPORATE" },
];

export const OWNER_TYPE_OPTIONS_MAP: any = {
  CORPORATE: {
    corporate: true,
  },
  INDIVIDUAL: {
    individual: true,
  },
};

export const ABSENTEE_OWNER_OPTIONS: any = [
  { label: "Absentee (In State)", value: "IN_STATE_ABSENTEE_OWNER" },
  { label: "Absentee (Out of State)", value: "OUT_OF_STATE_ABSENTEE_OWNER" },
];

export const ABSENTEE_OWNER_OPTIONS_MAP: any = {
  INSTATE: {
    inState: true,
  },
  OUTOFSTATE: {
    outState: true,
  },
};

export const OCCUPANCY_OPTIONS: any = [
  { label: "Owner Occupied", value: "OWNER_OCCUPIED" },
  { label: "Vacant", value: "VACANT" },
];

export const OCCUPANCY_OPTIONS_MAP: any = {
  OWNEROCCUPIED: {
    ownerOccupied: true,
  },
  VACANT: {
    vacant: true,
  },
};

// export const LOAN_TYPE_OPTIONS = [
//   { label: "ARM (Adjustable Rate Mortgage as of August 2009)", value: "ARM_ADJUSTABLE_RATE_MORTGAGE_AS_OF_AUGUST_2009" },
//   { label: "Balloon", value: "BAL" },
//   { label: "Construction", value: "CON" },
//   { label: "Closed End", value: "CEM" },
//   { label: "Commercial", value: "COM" },
//   { label: "Conventional", value: "COV" },
//   { label: "FHA", value: "FHA" },
//   { label: "Land Contract", value: "LCO" },
//   { label: "Line of Credit", value: "LOC" },
//   { label: "Loan Modification", value: "MOD" },
//   { label: "Negative Amortization", value: "NEG" },
//   { label: "Reverse", value: "REV" },
//   { label: "Silent Second", value: "SDP" },
//   { label: "Seller Take-Back", value: "SEL" },
//   { label: "SBA", value: "SBA" },
//   { label: "Stand Alone First", value: "SAF" },
//   { label: "Stand Alone Re-fi", value: "SAR" },
//   { label: "Stand Alone Second", value: "SAS" },
//   { label: "State VA", value: "SVA" },
//   { label: "Federal VA", value: "VA" },
//   { label: "USDA", value: "USDA" },
// ];

export const LOAN_TYPE_OPTIONS = [
  {
    label: "2nd Mortgage made to cover Down Payment",
    value: "SECOND_MORTGAGE_MADE_TO_COVER_DOWN_PAYMENT",
  },
  {
    label: "ARM (Adjustable Rate Mortgage as of August 2009)",
    value: "ARM_ADJUSTABLE_RATE_MORTGAGE_AS_OF_AUGUST_2009",
  },
  {
    label: "Amount keyed is an Aggregate amount",
    value: "AMOUNT_KEYED_IS_AN_AGGREGATE_AMOUNT",
  },
  { label: "Assumption", value: "ASSUMPTION" },
  { label: "Balloon", value: "BALLOON" },
  {
    label: "Building or Construction Loan",
    value: "BUILDING_OR_CONSTRUCTION_LOAN",
  },
  { label: "Cash", value: "CASH" },
  { label: "Closed-end Mortgage", value: "CLOSED_END_MORTGAGE" },
  { label: "Commercial", value: "COMMERCIAL" },
  { label: "Credit Line (Revolving)", value: "CREDIT_LINE_REVOLVING" },
  { label: "FHA", value: "FHA" },
  { label: "Fannie Mae/Freddie Mac", value: "FANNIE_MAE_FREDDIE_MAC" },
  {
    label: "Farmers Home Administration",
    value: "FARMERS_HOME_ADMINISTRATION",
  },
  {
    label: "Future Advance Clause / Open End Mortgage",
    value: "FUTURE_ADVANCE_CLAUSE_OPEN_END_MORTGAGE",
  },
  {
    label: "Land Contract (Argmt. Of Sale)",
    value: "LAND_CONTRACT_ARGMT_OF_SALE",
  },
  { label: "Loan Amount $1-9 billion", value: "LOAN_AMOUNT_1_9_BILLION" },
  { label: "Loan Amount $10-99 billion", value: "LOAN_AMOUNT_10_99_BILLION" },
  { label: "Mortgage Modification", value: "MORTGAGE_MODIFICATION" },
  { label: "Negative Amortization", value: "NEGATIVE_AMORTIZATION" },
  { label: "New Conventional", value: "NEW_CONVENTIONAL" },
  {
    label: "Non Purchase Money Mortgage",
    value: "NON_PURCHASE_MONEY_MORTGAGE",
  },
  { label: "Purchase Money Mortgage", value: "PURCHASE_MONEY_MORTGAGE" },
  {
    label: "Reverse Mortgage (Home Equity Conversion Mortgage)",
    value: "REVERSE_MORTGAGE_HOME_EQUITY_CONVERSION_MORTGAGE",
  },
  {
    label: "SBA Participation Trust Deed",
    value: "SBA_PARTICIPATION_TRUST_DEED",
  },
  { label: "Seller take-back", value: "SELLER_TAKE_BACK" },
  { label: "Stand Alone First", value: "STAND_ALONE_FIRST" },
  {
    label: "Stand Alone Refi (Refinance of Original Loan)",
    value: "STAND_ALONE_REFI_REFINANCE_OF_ORIGINAL_LOAN",
  },
  { label: "Stand Alone Second", value: "STAND_ALONE_SECOND" },
  { label: "State Veterans", value: "STATE_VETERANS" },
  { label: "Trade", value: "TRADE" },
  { label: "USDA", value: "USDA" },
  {
    label: "Undefined / Multiple Amounts",
    value: "UNDEFINED_MULTIPLE_AMOUNTS",
  },
  { label: "Unknown (DEFAULT)", value: "DEFAULT" },
  { label: "VA", value: "VA" },
];
export const ADVANCED_FILTER_KEYS = [
  "propertyCharacteristicsFilter",
  "auctionsPreforeclousreFilter",
  "ownershipFilter",
  "taxLienFilter",
  "valuationEquityFilter",
  "mortgageInfoFilter",
  "cashBuyersFilter",
  "demographicsFilter",
];

export const DEFAULT_FORM_VALUE = {
  propertyCharacteristicsFilter: {
    maxBathrooms: "",
    maxBedrooms: "",
    minBathrooms: "",
    minBedrooms: "",
    propertyType: [],
    yearBuiltMax: "",
    yearBuiltMin: "",
    classifications: [],
    homeFeatures: [],
    minLotSize: "",
    maxLotSize: "",
    minSquareFeet: "",
    maxSquareFeet: "",
    storiesMin: "",
    storiesMax: "",
  },
  auctionsPreforeclousreFilter: { auction: false },
  ownershipFilter: {
    absenteeOwner: [],
    occupancy: [],
    yearsOwnedMax: "",
    yearsOwnedMin: "",
    lastSalePriceMax: "",
    lastSalePriceMin: "",
    ownerType: [],
  },
  taxLienFilter: {
    taxDelinquentYearMax: "",
    taxDelinquentYearMin: "",
  },
  valuationEquityFilter: {
    assessedImprovementValueMax: "",
    assessedImprovementValueMin: "",
    assessedLandValueMax: "",
    assessedLandValueMin: "",
    assessedValueMax: "",
    assessedValueMin: "",
    equityPercentMax: "",
    equityPercentMin: "",
    estimatedEquityMax: "",
    estimatedEquityMin: "",
    estimatedValueMax: "",
    estimatedValueMin: "",
  },
  mortgageInfoFilter: {
    loanType: [],
    adjustable_rate: false,
    freeClear: false,
    mortgageMax: "",
    mortgageMin: "",
  },
  cashBuyersFilter: {
    cashBuyer: false,
    portfolioEquityMax: "",
    portfolioEquityMin: "",
    portfolioMortgageBalanceMax: "",
    portfolioMortgageBalanceMin: "",
    portfolioValueMax: "",
    portfolioValueMin: "",
    propertiesOwnedMax: "",
    propertiesOwnedMin: "",
  },
  demographicsFilter: {
    inherited: false,
    medianIncomeMax: "",
    medianIncomeMin: "",
    ownerAgeMax: "",
    ownerAgeMin: "",
  },
  marketStatus: [],
  searchTerm: "",
  mapShapesPayload: null,
  address: {},
  quickFilters: [],
};
export const DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE: AdvancedFilterCountByType =
  {
    propertyCharacteristicsFilter: 0,
    auctionsPreforeclousreFilter: 0,
    ownershipFilter: 0,
    taxLienFilter: 0,
    valuationEquityFilter: 0,
    mortgageInfoFilter: 0,
    cashBuyersFilter: 0,
    demographicsFilter: 0,
  };
export const DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE = {
  propertyCharacteristicsFilter: {},
  auctionsPreforeclousreFilter: {},
  ownershipFilter: {},
  taxLienFilter: {},
  valuationEquityFilter: {},
  mortgageInfoFilter: {},
  cashBuyersFilter: {},
  demographicsFilter: {},
  searchTerm: "",
};

export const SearchPropertyType: { [key: string]: string } = {
  A: "Address",
  C: "City",
  G: "Neighborhood",
  N: "County",
  S: "Street",
  Z: "Zip",
  T: "State",
};

export const SELECTED_FILTER_UPDATE_LIST = [
  "propertyCharacteristicsFilter",
  "auctionsPreforeclousreFilter",
  "ownershipFilter",
  "taxLienFilter",
  "valuationEquityFilter",
  "mortgageInfoFilter",
  "cashBuyersFilter",
  "demographicsFilter",
];

export const SAVED_FILTER_DISPLAY_COUNT = 5;

const PROPERTY_CHARACTERISTICS_FILTER_MAP: {
  [key: string]: FilterMapValueType;
} = {
  classifications: {
    label: "Classifications :",
    defaultValue: undefined,
    resetKeys: ["propertyCharacteristicsFilter.classifications"],
    type: FilterValueType.ARRAY,
  },
  homeFeatures: {
    label: "Home Features :",
    defaultValue: undefined,
    resetKeys: ["propertyCharacteristicsFilter.homeFeatures"],
    type: FilterValueType.ARRAY,
  },
  minBathrooms: {
    label: "Bathrooms :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minBathrooms",
      "propertyCharacteristicsFilter.maxBathrooms",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minBathrooms",
    maxKey: "maxBathrooms",
  },
  maxBathrooms: {
    label: "Bathrooms :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minBathrooms",
      "propertyCharacteristicsFilter.maxBathrooms",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minBathrooms",
    maxKey: "maxBathrooms",
  },
  minBedrooms: {
    label: "Bedrooms :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minBedrooms",
      "propertyCharacteristicsFilter.maxBedrooms",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minBedrooms",
    maxKey: "maxBedrooms",
  },
  maxBedrooms: {
    label: "Bedrooms :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minBedrooms",
      "propertyCharacteristicsFilter.maxBedrooms",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minBedrooms",
    maxKey: "maxBedrooms",
  },

  minLotSize: {
    label: "Lot Size :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minLotSize",
      "propertyCharacteristicsFilter.maxLotSize",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minLotSize",
    maxKey: "maxLotSize",
  },

  maxLotSize: {
    label: "Lot Size :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minLotSize",
      "propertyCharacteristicsFilter.maxLotSize",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minLotSize",
    maxKey: "maxLotSize",
  },

  minSquareFeet: {
    label: "Square Feet :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minSquareFeet",
      "propertyCharacteristicsFilter.maxSquareFeet",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minSquareFeet",
    maxKey: "maxSquareFeet",
  },
  maxSquareFeet: {
    label: "Square Feet :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.minSquareFeet",
      "propertyCharacteristicsFilter.maxSquareFeet",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "minSquareFeet",
    maxKey: "maxSquareFeet",
  },

  propertyType: {
    label: "Property Type :",
    defaultValue: [],
    resetKeys: ["propertyCharacteristicsFilter.propertyType"],
    type: FilterValueType.ARRAY,
  },
  storiesMin: {
    label: "Stories :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.storiesMin",
      "propertyCharacteristicsFilter.storiesMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "storiesMin",
    maxKey: "storiesMax",
  },
  storiesMax: {
    label: "Stories :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.storiesMin",
      "propertyCharacteristicsFilter.storiesMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "storiesMin",
    maxKey: "storiesMax",
  },

  yearBuiltMin: {
    label: "Year Built :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.yearBuiltMin",
      "propertyCharacteristicsFilter.yearBuiltMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "yearBuiltMin",
    maxKey: "yearBuiltMax",
  },
  yearBuiltMax: {
    label: "Year Built :",
    defaultValue: "",
    resetKeys: [
      "propertyCharacteristicsFilter.yearBuiltMin",
      "propertyCharacteristicsFilter.yearBuiltMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "yearBuiltMin",
    maxKey: "yearBuiltMax",
  },
};

const AUCTIONS_PREFORECLOUSRE_FILTER_MAP: {
  [key: string]: FilterMapValueType;
} = {
  auctionsPreforeclousreStatus: {
    label: "Foreclosure Status :",
    defaultValue: undefined,
    resetKeys: ["auctionsPreforeclousreFilter.auctionsPreforeclousreStatus"],
    type: FilterValueType.ARRAY,
    optionMap: AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS.reduce((acc, cuurent) => {
      return { ...acc, [cuurent.value]: cuurent.label };
    }, {}),
  },
  auctionsPreforeclousreSearchRange: {
    label: "Recording Date :",
    defaultValue: undefined,
    resetKeys: [
      "auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange",
    ],
    type: FilterValueType.RECORDING_DATE,
    optionMap: AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS.reduce(
      (acc, current) => {
        return {
          ...acc,
          ...(current.value
            ? { [current.value?.recordingDate]: current.label }
            : current.value),
        };
      },
      {}
    ),
  },
  auction: {
    label: "Active Auction",
    defaultValue: false,
    resetKeys: ["auctionsPreforeclousreFilter.auction"],
    type: FilterValueType.BOOLEAN,
  },
  exclude_foreclosure: {
    label: "Exclude Foreclosures",
    defaultValue: false,
    resetKeys: ["auctionsPreforeclousreFilter.exclude_foreclosure"],
    type: FilterValueType.BOOLEAN,
  },
  auctionDate: {
    label: "Auction Date :",
    defaultValue: undefined,
    resetKeys: ["auctionsPreforeclousreFilter.auctionDate"],
    type: FilterValueType.DATE_RANGE,
    startDateKey: "startDate",
    endDateKey: "endDate",
  },
};

const OWNERSHIP_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  absenteeOwner: {
    label: "Absentee Owner :",
    defaultValue: undefined,
    resetKeys: ["ownershipFilter.absenteeOwner"],
    type: FilterValueType.ARRAY,
    optionMap: ABSENTEE_OWNER_OPTIONS.reduce((acc: any, cuurent: any) => {
      return { ...acc, [cuurent.value]: cuurent.label };
    }, {}),
  },

  occupancy: {
    label: "Occupancy :",
    defaultValue: undefined,
    resetKeys: ["ownershipFilter.occupancy"],
    type: FilterValueType.ARRAY,
    optionMap: OCCUPANCY_OPTIONS.reduce((acc: any, cuurent: any) => {
      return { ...acc, [cuurent.value]: cuurent.label };
    }, {}),
  },
  ownerType: {
    label: "Owner Type :",
    defaultValue: undefined,
    resetKeys: ["ownershipFilter.ownerType"],
    type: FilterValueType.ARRAY,
    optionMap: OWNER_TYPE_OPTIONS.reduce((acc: any, cuurent: any) => {
      return { ...acc, [cuurent.value]: cuurent.label };
    }, {}),
  },
  lastSalePriceMin: {
    label: "Last Sale Price :",
    defaultValue: "",
    resetKeys: [
      "ownershipFilter.lastSalePriceMin",
      "ownershipFilter.lastSalePriceMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "lastSalePriceMin",
    maxKey: "lastSalePriceMax",
  },
  lastSalePriceMax: {
    label: "Last Sale Price :",
    defaultValue: "",
    resetKeys: [
      "ownershipFilter.lastSalePriceMin",
      "ownershipFilter.lastSalePriceMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "lastSalePriceMin",
    maxKey: "lastSalePriceMax",
  },
  yearsOwnedMin: {
    label: "Years Owned :",
    defaultValue: "",
    resetKeys: [
      "ownershipFilter.yearsOwnedMin",
      "ownershipFilter.yearsOwnedMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "yearsOwnedMin",
    maxKey: "yearsOwnedMax",
  },
  yearsOwnedMax: {
    label: "Years Owned :",
    defaultValue: "",
    resetKeys: [
      "ownershipFilter.yearsOwnedMin",
      "ownershipFilter.yearsOwnedMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "yearsOwnedMin",
    maxKey: "yearsOwnedMax",
  },
  lastSaleDateRange: {
    label: "Purchase Date :",
    defaultValue: undefined,
    resetKeys: ["ownershipFilter.lastSaleDateRange"],
    type: FilterValueType.DATE_RANGE,
    startDateKey: "startDate",
    endDateKey: "endDate",
  },
};

const TAX_LIEN_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  taxDelinquentYearMax: {
    label: "Tax Delinquent Year :",
    defaultValue: "",
    resetKeys: [
      "taxLienFilter.taxDelinquentYearMin",
      "taxLienFilter.taxDelinquentYearMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "taxDelinquentYearMin",
    maxKey: "taxDelinquentYearMax",
  },
  taxDelinquentYearMin: {
    label: "Tax Delinquent Year :",
    defaultValue: "",
    resetKeys: [
      "taxLienFilter.taxDelinquentYearMin",
      "taxLienFilter.taxDelinquentYearMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "taxDelinquentYearMin",
    maxKey: "taxDelinquentYearMax",
  },
};

const VALUATION_EQUITY_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  assessedImprovementValueMax: {
    label: "Assessed Improvement Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedImprovementValueMax",
      "valuationEquityFilter.assessedImprovementValueMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedImprovementValueMin",
    maxKey: "assessedImprovementValueMax",
  },
  assessedImprovementValueMin: {
    label: "Assessed Improvement Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedImprovementValueMax",
      "valuationEquityFilter.assessedImprovementValueMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedImprovementValueMin",
    maxKey: "assessedImprovementValueMax",
  },

  assessedLandValueMax: {
    label: "Assessed Land Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedLandValueMin",
      "valuationEquityFilter.assessedLandValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedLandValueMin",
    maxKey: "assessedLandValueMax",
  },
  assessedLandValueMin: {
    label: "Assessed Land Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedLandValueMin",
      "valuationEquityFilter.assessedLandValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedLandValueMin",
    maxKey: "assessedLandValueMax",
  },

  assessedValueMax: {
    label: "Assessed Value: ",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedValueMin",
      "valuationEquityFilter.assessedValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedValueMin",
    maxKey: "assessedValueMax",
  },
  assessedValueMin: {
    label: "Assessed Value: ",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.assessedValueMin",
      "valuationEquityFilter.assessedValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "assessedValueMin",
    maxKey: "assessedValueMax",
  },

  equityPercentMax: {
    label: "Equity Percent :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.equityPercentMin",
      "valuationEquityFilter.equityPercentMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "equityPercentMin",
    maxKey: "equityPercentMax",
  },
  equityPercentMin: {
    label: "Equity Percent :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.equityPercentMin",
      "valuationEquityFilter.equityPercentMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "equityPercentMin",
    maxKey: "equityPercentMax",
  },

  estimatedEquityMax: {
    label: "Estimated Equity :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.estimatedEquityMax",
      "valuationEquityFilter.estimatedEquityMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "estimatedEquityMin",
    maxKey: "estimatedEquityMax",
  },
  estimatedEquityMin: {
    label: "Estimated Equity :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.estimatedEquityMax",
      "valuationEquityFilter.estimatedEquityMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "estimatedEquityMin",
    maxKey: "estimatedEquityMax",
  },

  estimatedValueMax: {
    label: "Estimated Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.estimatedValueMin",
      "valuationEquityFilter.estimatedValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "estimatedValueMin",
    maxKey: "estimatedValueMax",
  },
  estimatedValueMin: {
    label: "Estimated Value :",
    defaultValue: "",
    resetKeys: [
      "valuationEquityFilter.estimatedValueMin",
      "valuationEquityFilter.estimatedValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "estimatedValueMin",
    maxKey: "estimatedValueMax",
  },
};

const MORTAGE_INFO_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  adjustable_rate: {
    label: "Exclude Adjustable",
    defaultValue: false,
    resetKeys: ["mortgageInfoFilter.adjustable_rate"],
    type: FilterValueType.BOOLEAN,
  },
  freeClear: {
    label: "Free and Clear",
    defaultValue: false,
    resetKeys: ["mortgageInfoFilter.freeClear"],
    type: FilterValueType.BOOLEAN,
  },
  loanType: {
    label: "All Loan Type :",
    defaultValue: undefined,
    resetKeys: ["mortgageInfoFilter.loanType"],
    type: FilterValueType.ARRAY,
    optionMap: LOAN_TYPE_OPTIONS.reduce((acc, cuurent) => {
      return { ...acc, [cuurent.value]: cuurent.label };
    }, {}),
  },
  mortgageMax: {
    label: "Mortgage Balance ($) :",
    defaultValue: "",
    resetKeys: [
      "mortgageInfoFilter.mortgageMax",
      "mortgageInfoFilter.mortgageMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "mortgageMin",
    maxKey: "mortgageMax",
  },
  mortgageMin: {
    label: "Mortgage :",
    defaultValue: "",
    resetKeys: [
      "mortgageInfoFilter.mortgageMax",
      "mortgageInfoFilter.mortgageMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "mortgageMin",
    maxKey: "mortgageMax",
  },
};

const CASH_BUYERS_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  cashBuyer: {
    label: "Cash Purchase",
    defaultValue: false,
    resetKeys: ["cashBuyersFilter.cashBuyer"],
    type: FilterValueType.BOOLEAN,
  },

  portfolioEquityMin: {
    label: "Portfolio Equity ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioEquityMin",
      "cashBuyersFilter.portfolioEquityMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioEquityMin",
    maxKey: "portfolioEquityMax",
  },
  portfolioEquityMax: {
    label: "Portfolio Equity ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioEquityMin",
      "cashBuyersFilter.portfolioEquityMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioEquityMin",
    maxKey: "portfolioEquityMax",
  },

  portfolioMortgageBalanceMin: {
    label: "Portfolio Debt ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioMortgageBalanceMin",
      "cashBuyersFilter.portfolioMortgageBalanceMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioMortgageBalanceMin",
    maxKey: "portfolioMortgageBalanceMax",
  },
  portfolioMortgageBalanceMax: {
    label: "Portfolio Debt ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioMortgageBalanceMin",
      "cashBuyersFilter.portfolioMortgageBalanceMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioMortgageBalanceMin",
    maxKey: "portfolioMortgageBalanceMax",
  },

  portfolioValueMin: {
    label: "Portfolio Value ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioValueMin",
      "cashBuyersFilter.portfolioValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioValueMin",
    maxKey: "portfolioValueMax",
  },
  portfolioValueMax: {
    label: "Portfolio Value ($) : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.portfolioValueMin",
      "cashBuyersFilter.portfolioValueMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "portfolioValueMin",
    maxKey: "portfolioValueMax",
  },

  propertiesOwnedMin: {
    label: "Properties Owned : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.propertiesOwnedMin",
      "cashBuyersFilter.propertiesOwnedMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "propertiesOwnedMin",
    maxKey: "propertiesOwnedMax",
  },
  propertiesOwnedMax: {
    label: "Properties Owned : ",
    defaultValue: "",
    resetKeys: [
      "cashBuyersFilter.propertiesOwnedMin",
      "cashBuyersFilter.propertiesOwnedMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "propertiesOwnedMin",
    maxKey: "propertiesOwnedMax",
  },
};
const DEMOGRAPHICS_FILTER_MAP: { [key: string]: FilterMapValueType } = {
  inherited: {
    label: "Inheritance",
    defaultValue: false,
    resetKeys: ["demographicsFilter.inherited"],
    type: FilterValueType.BOOLEAN,
  },
  medianIncomeMax: {
    label: "Household Income ($) : ",
    defaultValue: "",
    resetKeys: [
      "demographicsFilter.medianIncomeMax",
      "demographicsFilter.medianIncomeMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "medianIncomeMin",
    maxKey: "medianIncomeMax",
  },
  medianIncomeMin: {
    label: "Household Income ($) : ",
    defaultValue: "",
    resetKeys: [
      "demographicsFilter.medianIncomeMax",
      "demographicsFilter.medianIncomeMin",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "medianIncomeMin",
    maxKey: "medianIncomeMax",
  },
  ownerAgeMin: {
    label: "Owner Age : ",
    defaultValue: "",
    resetKeys: [
      "demographicsFilter.ownerAgeMin",
      "demographicsFilter.ownerAgeMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "ownerAgeMin",
    maxKey: "ownerAgeMax",
  },
  ownerAgeMax: {
    label: "Owner Age : ",
    defaultValue: "",
    resetKeys: [
      "demographicsFilter.ownerAgeMin",
      "demographicsFilter.ownerAgeMax",
    ],
    type: FilterValueType.MIN_MAX,
    minKey: "ownerAgeMin",
    maxKey: "ownerAgeMax",
  },
  divorceDate: {
    label: "Divorce Date :",
    defaultValue: undefined,
    resetKeys: ["demographicsFilter.divorceDate"],
    type: FilterValueType.DATE_RANGE,
    startDateKey: "startDate",
    endDateKey: "endDate",
  },
};

export const FILTER_LIST_MAP: FilterMapType = {
  propertyCharacteristicsFilter: PROPERTY_CHARACTERISTICS_FILTER_MAP,
  auctionsPreforeclousreFilter: AUCTIONS_PREFORECLOUSRE_FILTER_MAP,
  ownershipFilter: OWNERSHIP_FILTER_MAP,
  taxLienFilter: TAX_LIEN_FILTER_MAP,
  valuationEquityFilter: VALUATION_EQUITY_FILTER_MAP,
  mortgageInfoFilter: MORTAGE_INFO_FILTER_MAP,
  cashBuyersFilter: CASH_BUYERS_FILTER_MAP,
  demographicsFilter: DEMOGRAPHICS_FILTER_MAP,
};

export const MARKET_STATUS_METADATA = {
  label: "Market Status :",
  defaultValue: [],
  resetKeys: ["marketStatus"],
  type: FilterValueType.ARRAY,
  optionMap: MARKET_STATUS_OPTION.reduce((acc, cuurent) => {
    return { ...acc, [cuurent.value]: cuurent.label };
  }, {}),
};

export const QUICK_FILTERS_LIST: {
  categoryName: string;
  filters: { label: string; value: string }[];
}[] = [
  {
    categoryName: "Ownership & Vacancy Status",
    filters: [
      {
        label: "Absentee Owner",
        value: "ABSENTEE_OWNER",
      },
      {
        label: "Corporate Owned",
        value: "CORPORATE_OWNED",
      },
      {
        label: "Out Of State Absentee Owner",
        value: "OUT_OF_STATE_ABSENTEE_OWNER",
      },
      {
        label: "In State Absentee Owner",
        value: "IN_STATE_ABSENTEE_OWNER",
      },
      {
        label: "Owner Occupied",
        value: "OWNER_OCCUPIED",
      },
      {
        label: "Mailing Address Vacant",
        value: "MAILING_ADDRESS_VACANT",
      },
      {
        label: "Property Vacant",
        value: "VACANT",
      },
      {
        label: "Same Property & Mailing Address",
        value: "SAME_PROPERTY_AND_MAILING_ADDRESS",
      },
    ],
  },
  {
    categoryName: "Listing Status",
    filters: [
      {
        label: "Active Listing",
        value: "ACTIVE_LISTING",
      },
      {
        label: "Failed Listing",
        value: "FAILED_LISTING",
      },
      {
        label: "Pending Listing",
        value: "PENDING_LISTING",
      },
      {
        label: "Recently Sold",
        value: "RECENTLY_SOLD",
      },
      {
        label: "Canceled Listing", //Option not in Batch website
        value: "CANCELED_LISTING",
      },
    ],
  },
  {
    categoryName: "Preforeclosure & Auction",
    filters: [
      {
        label: "Active Auction",
        value: "ACTIVE_AUCTION",
      },
      {
        label: "Notice Of Sale",
        value: "NOTICE_OF_SALE",
      },
      {
        label: "Preforeclosure",
        value: "PREFORECLOSURE",
      },
      {
        label: "Notice Of Default",
        value: "NOTICE_OF_DEFAULT",
      },
      {
        label: "Notice Of Lis Pendens",
        value: "NOTICE_OF_LIS_PENDENS",
      },
    ],
  },
  {
    categoryName: "Equity & Loan",
    filters: [
      {
        label: "Cash Buyer",
        value: "CASH_BUYER",
      },
      {
        label: "Free & Clear",
        value: "FREE_AND_CLEAR",
      },
      {
        label: "High Equity",
        value: "HIGH_EQUITY",
      },
      {
        label: "Low Equity",
        value: "LOW_EQUITY",
      },
      { label: "Unknown Equity", value: "UNKNOWN_EQUITY" },
    ],
  },
  {
    categoryName: "Other",
    filters: [
      {
        label: "Expired Listing",
        value: "EXPIRED_LISTING",
      },

      {
        label: "Fix and Flip",
        value: "FIX_AND_FLIP",
      },

      {
        label: "For sale by owner",
        value: "FOR_SALE_BY_OWNER",
      },
      {
        label: "Has HOA",
        value: "HAS_HOA",
      },
      {
        label: "Not Has HOA",
        value: "NOT_HAS_HOA",
      },

      {
        label: "Inherited",
        value: "INHERITED",
      },

      {
        label: "Listed Below Market Price",
        value: "LISTED_BELOW_MARKET_PRICE",
      },

      {
        label: "On Market",
        value: "ON_MARKET",
      },

      {
        label: "Out Of State Owner",
        value: "OUT_OF_STATE_OWNER",
      },

      {
        label: "Tax Default",
        value: "TAX_DEFAULT",
      },
      {
        label: "Tired Landlord",
        value: "TIRED_LANDLORD",
      },

      {
        label: "Vacant Lot",
        value: "VACANT_LOT",
      },
    ],
  },
];

export const SEARCH_ON_BLUR_INPUTS_LIST = [
  "propertyCharacteristicsFilter.minLotSize",
  "propertyCharacteristicsFilter.maxLotSize",
  "propertyCharacteristicsFilter.yearBuiltMin",
  "propertyCharacteristicsFilter.yearBuiltMax",
  "propertyCharacteristicsFilter.storiesMin",
  "propertyCharacteristicsFilter.storiesMax",
  "propertyCharacteristicsFilter.minBedrooms",
  "propertyCharacteristicsFilter.maxBedrooms",
  "propertyCharacteristicsFilter.minBathrooms",
  "propertyCharacteristicsFilter.maxBathrooms",
  "ownershipFilter.yearsOwnedMin",
  "ownershipFilter.yearsOwnedMax",
  "ownershipFilter.lastSalePriceMin",
  "ownershipFilter.lastSalePriceMax",
  "taxLienFilter.taxDelinquentYearMin",
  "taxLienFilter.taxDelinquentYearMax",
  "valuationEquityFilter.equityPercentMin",
  "valuationEquityFilter.equityPercentMax",
  "valuationEquityFilter.estimatedEquityMin",
  "valuationEquityFilter.estimatedEquityMax",
  "valuationEquityFilter.estimatedValueMin",
  "valuationEquityFilter.estimatedValueMax",
  "valuationEquityFilter.assessedValueMin",
  "valuationEquityFilter.assessedValueMax",
  "valuationEquityFilter.assessedLandValueMin",
  "valuationEquityFilter.assessedLandValueMax",
  "valuationEquityFilter.assessedImprovementValueMin",
  "valuationEquityFilter.assessedImprovementValueMax",
  "mortgageInfoFilter.mortgageMin",
  "mortgageInfoFilter.mortgageMax",
  "cashBuyersFilter.propertiesOwnedMin",
  "cashBuyersFilter.propertiesOwnedMax",
  "cashBuyersFilter.portfolioValueMin",
  "cashBuyersFilter.portfolioValueMax",
  "cashBuyersFilter.portfolioEquityMin",
  "cashBuyersFilter.portfolioEquityMax",
  "cashBuyersFilter.portfolioMortgageBalanceMin",
  "cashBuyersFilter.portfolioMortgageBalanceMax",
  "demographicsFilter.ownerAgeMin",
  "demographicsFilter.ownerAgeMax",
  "demographicsFilter.medianIncomeMin",
  "demographicsFilter.medianIncomeMax",
];

export const SAVED_FILTER_UPDATION_KEYS = [
  "marketStatus",
  "quickFilters",
  "propertyCharacteristicsFilter",
  "auctionsPreforeclousreFilter",
  "ownershipFilter",
  "taxLienFilter",
  "valuationEquityFilter",
  "mortgageInfoFilter",
  "cashBuyersFilter",
  "demographicsFilter",
];
