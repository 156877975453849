import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../molecules/table/CustomTable";
import { fetchCampaignList, fetchCampaignStats } from "./sagaActions";
import { campaignsData, getCampaignListIsLoading, getCampaignsListMore } from "./selectors";
import SearchReflation from "../../assets/icons/searchReflation";
import Rocket from "../../assets/icons/rocket";
import Button from "../../atoms/Button";
import Announcement from "../../assets/icons/announcement";
import { useDbounce } from "../../hooks/debounce.hook";
import { setCampaignListIsLoading } from "./reducer";
import Loader from "../../atoms/Loader";
import { followUpDateFormatter } from "../../utils/utils";
import { campaignTable, STATUS_MAPPING } from "./constants";
import { campaignTableProps } from "./interface";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import CallBackNo from "../createCampaign/CallBackNo";
import { fetchLeadStatus } from "../campaignDetails/campaignDetailsService";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../auth/enum";

const CampaignTable = ({
  onActionClick,
  handleOpenCreateCampaignModal,
  handleClearSearch,
  handleNextPage,
  filter,
  handleSorting,
}: campaignTableProps) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const res = useSelector((state: any) => campaignsData(state));

 

  const campaignListIsLoading = useSelector((state: any) =>
    getCampaignListIsLoading(state)
  );
  const [exportListLoading, setExportListLoading] = useState<{ [key: string]: boolean }>({});
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [openCallBackNoModal, setOpenCallBackNoModal] = useState<boolean>(false);
  const[loadingText,setLoadingText]= useState("")
  const fetchMoreList = useSelector((state) => getCampaignsListMore(state));
  const debounce = useDbounce(500, (data: any) => {
    if(!fetchMoreList){
    dispatch(
      fetchCampaignList({
        search: data.searchText,
        index: data.pageNumber,
        size: data.limit,
        filter: data.filter,
        sortDirection: data.sortDirection,
        sortKey: data.sortKey,
      })
    );
    dispatch(
      fetchCampaignStats({
        search: data.searchText,
        index: data.pageNumber,
        size: data.limit,
        filter: data.filter,
      })
    );
  }
  });

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleChange = async (id:any,calValue:any,value:any,) => {
    try {
      setExportListLoading(prevState => ({
        ...prevState,
        [value]: true,
      }));
      const payload = {
        id: id,
        status: STATUS_MAPPING[calValue],
        preview: false,
      };
      const result: any = await fetchLeadStatus(payload);
      setExportListLoading(prevState => ({
        ...prevState,
        [value]: false,
      }));
      handleShowToast("List downloaded successfully!", ToastType.success);
      return result?.data?.map((item: any) => ({
        ...item,
      }));
    } catch (error) {
      console.log(error)
      setExportListLoading(prevState => ({
        ...prevState,
        [value]: false,
      }));
    }
  };

  useEffect(() => {
    let intervalId:NodeJS.Timeout;
    if (exportListLoading) {
      const texts = ["Exporting...","Still working...", "Almost there...", "Just a moment..."];
      let index = 0;
      intervalId = setInterval(() => {
        setLoadingText(texts[index]);
        index = (index + 1) % texts.length;
      }, 5000);
    } else {
      setLoadingText("");
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      
    };
  }, [exportListLoading]);

  useEffect(() => {
    if (
      filter.searchText.length > 0 &&
      filter.searchText.length !== filter.searchText.trim().length
    ) {
      return;
    }
    if (filter.pageNumber === 1) {
      dispatch(setCampaignListIsLoading(true));
    }
    debounce(filter);
  }, [filter]);

  useEffect(() => {
    const listData = res.campaignList?.map((campaign: any) => {
      return {
        ...campaign,
        callCount:campaign?.callCount != "0" ? campaign?.callCount : "—",
        inQueueCalls:campaign?.inQueueCalls != "0" ? campaign?.inQueueCalls : "—",
        leadCount: campaign.leadCount != "0" ? campaign.leadCount : "—",
        hangupCalls: campaign.hangupCalls != "0" ? campaign.hangupCalls : "—",
        deadCalls: campaign.deadCalls  != "0" ? campaign.deadCalls : "—",
        wrongNumberCalls : campaign.wrongNumberCalls != "0" ? campaign.wrongNumberCalls : "—",
        failedCalls :campaign.failedCalls != "0" ? campaign.failedCalls : "—",
        dncCalls: campaign.dncCalls != "0" ? campaign.dncCalls : "—",
        startDate: followUpDateFormatter(
          campaign.startDate ? new Date(campaign.startDate) : null
        ),
        voiceEmail: campaign?.voicemailCalls != "0" ? campaign.voicemailCalls : "—",
      };
    });
    setTableData(listData ?? []);
  }, [res]);

  return (
    <>
      {campaignListIsLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <CustomTable
          rows={tableData || []}
          columns={campaignTable}
          total={res?.total}
          currentPageIndex={1}
          onActionClick={onActionClick}
          tableStriped
          search={filter?.searchText || (filter?.filter != "all" ? filter?.filter : '')}
          sortKey={filter.sortKey}
          sortDirection={filter.sortDirection}
          handleNextPage={handleNextPage}
          handleSorting={handleSorting}
          hasMore={tableData.length < res?.total}
          popupClicked={handleChange}
          exportListLoading={exportListLoading}
          loadingText={loadingText}
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
            description:
              "We couldn’t find any results that match your search or filtering criteria.",
            button1:
            profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? (
              <Button
                label="Create Campaign"
                className="primary md"
                prefix={<Rocket />}
                disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
              />
            ) :
            !profileInfo?.organisation?.callbackPhone ?
              <Button
              label="Create Campaign"
              className="primary md"
              prefix={<Rocket />}
              action={() => setOpenCallBackNoModal(true)}
            />:
            <Button
              label="Create Campaign"
              className="primary animated"
              prefix={<Rocket />}
              action={handleOpenCreateCampaignModal}
            />
            ,
            button2: (
              <Button
                label="Clear search"
                className="outline"
                action={handleClearSearch}
              />
            ),
          }}
          emptyTableMetaData={{
            icon: <Announcement />,
            title: "Start your first campaign",
            description:
              "Click the button below to get started with your first campaign.",
            button1: 
            profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? (
              <Button
                label="Create Campaign"
                className="primary md"
                prefix={<Rocket />}
                disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
              />
            ) :
            !profileInfo?.organisation?.callbackPhone ?
              <Button
              label="Create Campaign"
              className="primary md"
              prefix={<Rocket />}
              action={() => setOpenCallBackNoModal(true)}
            />:
            <Button
              label="Create Campaign"
              className="primary animated"
              prefix={<Rocket />}
              action={handleOpenCreateCampaignModal}
            />,
          }}
        ></CustomTable>
       
      )}
       {openCallBackNoModal && (
          <CallBackNo
            open={openCallBackNoModal}
            closeModal={()=>setOpenCallBackNoModal(false)}
          
          ></CallBackNo>
        )}
    </>
  );
};

export default CampaignTable;
