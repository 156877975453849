import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import {
  getSubscriptions,
  getSubscriptionsIsLoading,
} from "../billing/selectors";
import { PermissionListEnum } from "./enum";
import { getRefreshPage } from "./selectors";

const GeneralAccessWrapper = ({ children }: { children: React.ReactNode }) => {
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const subscriptionsLoading = useSelector((state) =>
    getSubscriptionsIsLoading(state)
  );
  const refreshPage = useSelector((state) => getRefreshPage(state));
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  if (
    !sunscriptions.hasOwnProperty("generalSubData") ||
    (subscriptionsLoading && refreshPage)
  ) {
    return <GlobalLoader></GlobalLoader>;
  }
  if (
    !(
      !sunscriptions?.generalSubData ||
      sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined" ||
      (profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER &&
        profileInfo?.isSeatSubscriptionActive === false)
    )
  ) {
    return <>{children}</>;
  } else {
    return <Navigate to="/settings" />;
  }
};
export default GeneralAccessWrapper;
