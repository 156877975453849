import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAPI, putAPI } from "../../apis/apis";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchCampaignData({ payload }: any) {
  const { id } = payload;
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/detail-table?filter=all&search=&index=1&size=20&campaignId=${
          id?.id || id
        }`,
      headers: {},
    });
    yield put(slice.setCampaignData(response));
  } catch (error) {
    console.log("error=", error);
  }
}

function* fetchCancelData(data: any) {
  yield put(slice.setIsLoading(true));
  try {
    const response: ResponseGenerator = yield putAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/cancel/${data?.payload?.id}`,
      headers: {},
    });
    yield put(
      addToast({
        id: new Date().getTime(),
        message: response?.data?.message,
        type: ToastType.success,
      })
    );
  } catch (error: any) {
    console.log("error in cancelling campaign", error);
    yield put(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  } finally {
    yield put(slice.setIsLoading(false));
  }
  yield call(fetchCampaignData, { payload: { id: data?.payload?.id } });
}

function* fetchEditData({ payload }: any) {
  yield put(slice.setIsLoadingEdit(true));
  try {
    const editPayload = {
      name: payload?.data?.campaignName,
      startDate: payload?.data?.startDate?.split("T")[0],
      dpn: payload?.data?.optimizeCampaign
        ? "3"
        : payload?.data?.dialsPerNumber,
      optimizeEnabled: payload?.data?.optimizeCampaign,
      attemptFrequency: payload?.data?.optimizeCampaign
        ? "3"
        : payload?.data?.maxAttemptPerNumber,
        callbackPhone: payload?.data?.callbackPhone,
    };

    const response: ResponseGenerator = yield putAPI({
      url: process.env.REACT_APP_BASE_URL + `/campaign/edit/${payload?.id?.id}`,
      data: editPayload,
      headers: {},
    });
    yield put(
      addToast({
        id: new Date().getTime(),
        message: response?.data?.message,
        type: ToastType.success,
      })
    );
  } catch (error: any) {
    console.log("error in editing campaign", error);
    yield put(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  } finally {
    yield put(slice.setIsLoadingEdit(false));
  }
  yield call(fetchCampaignData, { payload: { id: payload.id.id } });
  yield call(fetchTopSectionData, { payload: { id: payload.id } });
}

function* fetchTopSectionData({ payload }: any) {
  const { id } = payload;
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/campaign/campaign-progress/${id.id}`,
      data: payload.data,
      headers: {},
    });
    yield put(slice.setTopSectionData(response));
  } catch (error) {
    console.log("error", error);
    yield put(slice.setTopSectionData(error));
  }
}

function* fetchLeadsData({ payload }: any) {
  const {
    id,
    searchLeadsText,
    selectedAttempt,
    selectedSortDirection,
    selectedSortKey,
    leadFilterBy,
    index,
    size,
    listArray
  } = payload;

  // const urlSearchParams = new URLSearchParams();

  // if (searchLeadsText) {
  //   urlSearchParams.set("search", searchLeadsText);
  // }

  // if (selectedAttempt || selectedAttempt === 0) {
  //   urlSearchParams.set("attempt_id", selectedAttempt);
  // }

  // if (selectedSortDirection) {
  //   urlSearchParams.set("sortDirection", selectedSortDirection);
  // }

  // if (selectedSortKey) {
  //   urlSearchParams.set("sortKey", selectedSortKey);
  // }

  // if (leadFilterBy) {
  //   urlSearchParams.set("type", leadFilterBy);
  // }
  // urlSearchParams.set("index", index ?? "");
  // if (size) {
  //   urlSearchParams.set("size", size);
  // }

  const params = {
    search: searchLeadsText,
    attempt_id: selectedAttempt,
    sortDirection: selectedSortDirection,
    sortKey: selectedSortKey,
    type: leadFilterBy,
    index,
    size,
    list_ids:listArray
    
  }

  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/leads/${id.id}?`,
        data: params,
        headers: {},
    });
    yield all([
      put(
        index ===1
          ? slice.setLeadsData(response.data)
          : slice.appendLeadsList(response.data)
      ),
      put(slice.setLeadsLoading(false)),
    ]);
    yield put(slice.settotal(response?.data?.total));
  } catch (error) {
    console.log("error", error);
    yield put(slice.setLeadsLoading(false));
  } finally {
    console.log("finally");
  }
}


function* fetchActivityData({ payload }: any) {
  const { id, selectedAttempt, index, size ,listArray} = payload;
  const params = {
    attempt_id: selectedAttempt,
    index,
    size,
    list_ids:listArray
    
  }
  if (index === 0) yield put(slice.setActivityLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/leads/activity/${id.id}?`,
      data: params,headers: {},
    });

    yield all([
      put(
        !index
          ? slice.setActivityData(response.data)
          : slice.appendActivityData(response.data)
      ),
      put(slice.setActivityLoading(false)),
    ]);
    yield put(slice.setActivitytotal(response?.data?.total));
  } catch (error) {
    console.log("error", error);
    yield put(slice.setActivityLoading(false));
  } finally {
    yield put(slice.setActivityLoading(false));
  }
}

function* fetchTopMatrixData({ payload }: any) {
  const { id, selectedAttempt,listArray } = payload;
  const params = {
    attempt_id: selectedAttempt,
    list_ids:listArray
    
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/leads/dashboard/${id.id}?`,
        data: params,
      headers: {},
    });
    yield put(slice.setTopMatrixData(response));
  } catch (error) {
    console.log("error", error);
  }
}

function* fetchGraphData({ payload }: any) {
  const { id, selectedAttempt,listArray} = payload;
  const params = {
    attempt_id: selectedAttempt,
    list_ids:listArray
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/graph/${id.id}?`,
        data: params,
      headers: {},
    });
    yield put(slice.setGraphData(response));
  } catch (error) {
    console.log("error", error);
  }
}

function* fetchDetails({ payload }: any) {
  const { id } = payload;
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/campaign/edit/${id}`,
      headers: {},
    });
    yield put(slice.setCampaignDetail(response));
  } catch (error) {
    console.log("error=", error);
  }
}

function* fetchAttemptId({ payload }: any) {
  const { id } = payload;
  yield put(slice.setIsAttemptSelected(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/campaign/get-attempt/${id.id}`,
      headers: {},
    });
    yield put(slice.setAttemptDetails(response?.data));
  } catch (error) {
    console.log("error=", error);
  } finally {
    yield put(slice.setIsAttemptSelected(false));
  }
}
function* fetchPropertyCountByCampaign({ payload }: any) {
  const { id } = payload;
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/get-property-count/${id.id}`,
      headers: {},
    });
    yield put(slice.setPropertyCountByCampaign(response.data));
  } catch (error) {}
}


function* fetchHungupData({ payload }: any) {
  const { id ,status,preview} = payload;
  try {
    yield put(slice.setHungUpDataLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/campaign/attempt-status-data/${id}?status=${status}&isPreview=${preview}`,
      headers: {},
    });
    yield all([
      put( slice.setHungUpData(response.data)),
      put(slice.setHungUpDataLoading(false)),
    ]);

  } catch (error) {
    console.log("error=", error);
  }
}

function* campaignDetailSaga() {
  yield takeLatest(sagaActions.cancelCampaignAction, fetchCancelData);
  yield takeLatest(sagaActions.editCampaignAction, fetchEditData);
  yield takeLatest(sagaActions.campaignDataAction, fetchCampaignData);
  yield takeLatest(sagaActions.topSectionAction, fetchTopSectionData);
  yield takeLatest(sagaActions.leadsAction, fetchLeadsData);
  yield takeLatest(sagaActions.activityAction, fetchActivityData);
  yield takeLatest(sagaActions.topMatrixAction, fetchTopMatrixData);
  yield takeLatest(sagaActions.graphAction, fetchGraphData);
  yield takeLatest(sagaActions.campaignDetail, fetchDetails);
  yield takeLatest(sagaActions.getAttemptIdAction, fetchAttemptId);
  yield takeLatest(
    sagaActions.fetchPropertyCountByCampaign,
    fetchPropertyCountByCampaign
  );
  yield takeLatest(
    sagaActions.fetchHungupData,
    fetchHungupData
  );

  
}

export default campaignDetailSaga;
