import { ClickAwayListener } from "@mui/material";
import { format as formatDate } from "date-fns";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentIcon from "../../assets/icons/commentIcon";
import Plus from "../../assets/icons/plus";
import Send from "../../assets/icons/send";
import Button from "../../atoms/Button";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import styles from "./Chat.module.scss";
import Message from "./Message";

const SAMPLE_MESSAGE = {
  dateTime: "Today 2:20pm",
  body: "Spoke with John Smith about his property at 123 Maple Street. He is considering selling due to relocation for a new job. Asking price is around $250,000, with some flexibility. Plan to follow up in 5 days, send more info, and potentially schedule a meeting. I set my follow up reminder to call back here in 5 days. Could be a great opportunity.",
  createdByName: "Andi Lane",
};

const Chat = ({
  chatLoading,
  sendLoading,
  messages,
  onSend,
  title = "My Notes",
  handleNextPage,
  totalCount,
  smartSummary,
  onEdit,
}: {
  chatLoading: boolean;
  sendLoading: boolean;
  messages: any[];
  onSend: Function;
  onEdit:Function
  title: string;
  handleNextPage: Function;
  totalCount: number;
  smartSummary?: boolean;
}) => {
  const [addNote, setAddNote] = useState(false);
  const TodayDate = new Date().setHours(0, 0, 0, 0);
  const [editorVisible, setEditorVisible] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<string>("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleSend = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    if (
      !htmlContent ||
      !editorVisible ||
      !editorState.getCurrentContent().getPlainText().trim()
    ) {
      return;
    }
    try {
      await onSend(htmlContent);
    } catch (error) {
    } finally {
      setEditorState(EditorState.createEmpty());
      setEditorVisible(false);
      setAddNote(false);
    }
  };

  const handleEdit = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    if (
      !htmlContent ||
      !editorVisible ||
      !editorState.getCurrentContent().getPlainText().trim()
    ) {
      return;
    }
    try {
      await onEdit(selectedCommentId, htmlContent);
    } catch (error) {
    } finally {
      setEditorState(EditorState.createEmpty());
      setEditorVisible(false);
      setAddNote(false);
      setSelectedCommentId("");
    }
  };

  const handleEditMenuItemClick = useCallback((message: any) => {
    const { comment, body } = message;
    const { contentBlocks, entityMap } = convertFromHTML(comment || body);

    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
    setEditorState(newEditorState);
    setEditorVisible(true);
    setAddNote(true)
  }, []);

  const handleEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
  };
  return (
    <div className={` ${styles.chat} `}>
      <div className="wrapper bottom-zero">
        <div
          className={` ${styles.chat__notes} ${
            editorVisible ||
            messages.length === 0 ||
            messages.length === undefined ||
            addNote
              ? styles.active
              : ""
          } `}
        >
          <div
            className={` ${styles.chat__header} ${
              smartSummary ? styles.smartSummary : ""
            }   dflex justifySpaceBetween`}
          >
            <h2>{title}</h2>
            <Button
              prefix={<Plus />}
              className="outline borderGradient"
              action={() => setAddNote(true)}
            />
          </div>
          {!chatLoading && (
            <div className={` ${styles.chat__list} `}  id="scrollableDiv">
              {(editorVisible ||
                messages.length === 0 ||
                messages.length === undefined) &&
                (!chatLoading || addNote) && (
                  <>
                    {[...Array(3)].map((_, index) => (
                      <Message
                        handleClick={() => {}}
                        message={SAMPLE_MESSAGE}
                        setSelectedCommentId={setSelectedCommentId}
                        dateTime={SAMPLE_MESSAGE.dateTime}
                        body={SAMPLE_MESSAGE.body}
                        createdByName={SAMPLE_MESSAGE.createdByName}
                        id={`${index}`}
                        alignRight={false}
                      />
                    ))}
                  </>
                )}
              {messages.length > 0 && (
                <div>
                  <InfiniteScroll
                    dataLength={messages?.length}
                    next={() => handleNextPage && handleNextPage()}
                    hasMore={messages.length < totalCount}
                    scrollableTarget="scrollableDiv"
                    loader={
                      <div className="table-loader">
                        <Loader size={20}></Loader>
                      </div>
                    }
                  >
                    <>
                      {messages?.map((message) => (
                        <>
                          <Message
                            key={`${message?.id}_chat`}
                            handleClick={handleEditMenuItemClick}
                            dateTime=// {
                            //   TodayDate !==
                            //   new Date(
                            //     message?.createdAt ?? message?.CreatedAt
                            //   ).setHours(0, 0, 0, 0)
                            //     ? formatDate(
                            //         message?.createdAt,
                            //         "MMM dd, yyyy  hh:mm aaaaa'm'"
                            //       )
                            //     : `Today ${formatDate(
                            //         message?.createdAt,
                            //         "hh:mm aaaaa'm'"
                            //       )}`
                            // }
                            {new Date(message?.createdAt).toLocaleString()}
                            body={message?.body}
                            createdByName={message?.createdByName}
                            id={`${message?.id}`}
                            setSelectedCommentId={setSelectedCommentId}
                            alignRight={message?.alignRight || false}
                            message={message}
                          />
                        </>
                      ))}
                    </>
                  </InfiniteScroll>
                </div>
              )}
            </div>
          )}
        </div>
        {!chatLoading &&
          (addNote ||
            messages.length === 0 ||
            messages.length === undefined) && (
            <>
              <div className={` ${styles.chat__addNote} `}>
                <ClickAwayListener
                  onClickAway={() => {
                    setEditorVisible(false);
                    setAddNote(false);
                  }}
                >
                  <div className={` ${styles.chat__addNoteTextarea} `}>
                    {!editorVisible && (
                      <textarea
                        placeholder="Start writing your first note"
                        onClick={() => {
                          setEditorVisible(true);
                          setEditorState(
                            EditorState.moveFocusToEnd(editorState)
                          );
                        }}
                      />
                    )}
                    {editorVisible && (
                      <div
                        id="editor"
                        className={` ${styles.chat__addNoteEditor}`}
                      >
                        <Editor
                          editorState={editorState}
                          wrapperClassName="wrapperClassName"
                          editorClassName="lead-activity-editor"
                          onEditorStateChange={handleEditorStateChange}
                          handlePastedText={() => {
                            setTimeout(() => {
                              const editorNode =
                                document.getElementsByClassName(
                                  "lead-activity-editor"
                                )[0];
                              editorNode.scroll(0, editorNode.scrollHeight);
                            }, 0);
                            return false;
                          }}
                          toolbar={{
                            options: ["inline", "list"],
                            list: { options: ["ordered", "unordered"] },
                          }}
                          spellCheck={true}
                        />
                      </div>
                    )}
                    <span
                      onClick={() =>
                        selectedCommentId ? handleEdit() : handleSend()
                      }
                      role={
                        !editorVisible ||
                        !editorState.getCurrentContent().hasText()
                          ? "disabled"
                          : "link"
                      }
                      className={` ${styles.chat__addNoteSend} flex alignCenter justifyCenter`}
                    >
                      {sendLoading ? <Loader size={18} /> : <Send />}
                    </span>
                  </div>
                </ClickAwayListener>
                {!editorVisible &&
                  (messages.length === 0 || messages.length === undefined) && (
                    <EmptyPlaceholder
                      icon={<CommentIcon />}
                      title={"Kick things off with your first note!"}
                      description="The road to success starts with one note—make it count!"
                      bgGray={true}
                      extraStyles={{ maxWidth: "100%" }}
                      blared
                    />
                  )}
              </div>
            </>
          )}
        {chatLoading && (
          <div
            className={` ${styles.chat__loader} flex alignCenter justifyCenter`}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
export default Chat;
