import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CurrencyDollar from "../../../../assets/icons/currencyDoller";
import Percent from "../../../../assets/icons/percent";
import DropDown from "../../../../atoms/DropDown";
import Input from "../../../../atoms/Input";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { useDbounce } from "../../../../hooks/debounce.hook";
import CustomTable from "../../../../molecules/table/CustomTable";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { formatNewCurrency } from "../../../../utils/utils";
import {
  ProfitMarginOptions,
  amortizationScheduleColumns,
} from "../../columnsData";
import {
  fetchAmortizationCalculatorAction,
  fetchWholesaleCalculatorAction,
} from "../../property.sagaAction";
import {
  getPropertyDetails,
  getWholesaleCalculatorList,
  getWholesaleCalculatorloading,
  getamortizationCalculatorList,
  getamortizationCalculatorloading,
} from "../../property.selector";
import {
  postAmortizationCalculator,
  postWholesaleCalculator,
} from "../../property.service";
import { Schedule } from "../../property.type";
import { amortizationCalculatorSchema } from "../../validation";
import CreativeFinance from "../creativeFinance/CreativeFinance";
import styles from "./CalculatorTab.module.scss";

function calculatePaymentPerPeriod(
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod =
    principal *
    (periodInterestRate /
      (1 - Math.pow(1 + periodInterestRate, -totalPeriods)));
  if (!isFinite(paymentPerPeriod) || isNaN(paymentPerPeriod)) {
    return 0;
  }

  return paymentPerPeriod;
}

const calculateAmortizationSchedule = (
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) => {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod = calculatePaymentPerPeriod(
    principal,
    annualInterestRate,
    totalYears,
    isMonthly
  );

  const generateSchedule = (
    period: number,
    balance: number,
    schedule: Schedule[]
  ): Schedule[] => {
    if (period > totalPeriods || balance <= 0) {
      return schedule;
    }

    const interestForPeriod = balance * periodInterestRate;
    let principalForPeriod = paymentPerPeriod - interestForPeriod;
    // Preventing principalForPeriod from going negative due to rounding in the final period
    principalForPeriod =
      principalForPeriod > balance ? balance : principalForPeriod;
    const endingBalance = balance - principalForPeriod;

    schedule.push({
      period: period,
      totalPayment: parseFloat(paymentPerPeriod.toFixed(2)),
      interest: parseFloat(interestForPeriod.toFixed(2)),
      principal: parseFloat(principalForPeriod.toFixed(2)),
      endingBalance: parseFloat(endingBalance.toFixed(2)),
    });

    return generateSchedule(period + 1, endingBalance, schedule);
  };

  return generateSchedule(1, principal, []);
};

interface CalculatorTabsProps {
  selectedPropertyId: string;
  type: string;
}

const CalculatorTab = ({ selectedPropertyId, type }: CalculatorTabsProps) => {
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const dispatch = useDispatch();
  const {
    formState: { errors, isValid, isDirty },
    register,
    watch,
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      afterRepairValue: 0,
      costOfRepairs: 0,
      assignmentFee: 0,
      loanAmount: 0,
      loanTerm: "",
      interestRate: "",
      profitMargin: ProfitMarginOptions[0].value,
    },
    resolver: yupResolver(amortizationCalculatorSchema),
  });

  const Profitmargin = watch("profitMargin");

  const [wholesaleLoading, setWholesaleLoading] = useState(false);
  const [wholesaleFailLoading, setWholesaleFailLoading] = useState(false);
  const [amortizationFailLoading, setAmortizationFailLoading] = useState(false);
  const [amortizationLoading, setAmortizationLoading] = useState(false);
  const amortizationCalculatorloading = useSelector((state) =>
    getamortizationCalculatorloading(state)
  );
  const wholesaleCalculatorloading = useSelector((state) =>
    getWholesaleCalculatorloading(state)
  );

  const wholesaleCalculatorData = useSelector((state) =>
    getWholesaleCalculatorList(state)
  );
  const amortizationsaleCalculatorData = useSelector((state) =>
    getamortizationCalculatorList(state)
  );

  const afterRepairValue = parseFloat(
    (watch("afterRepairValue") ?? "").toString().replace(/,/gi, "")
  );
  const costOfRepairs = parseFloat(
    (watch("costOfRepairs") ?? "").toString().replace(/,/g, "")
  );
  const assignmentFee = parseFloat(
    (watch("assignmentFee") ?? "").toString().replace(/,/g, "")
  );

  const amortizationdebounce = useDbounce(
    100,
    async ({
      loanAmount,
      loanTerm,
      interestRate,
      organisationId,
    }: {
      loanAmount: any;
      loanTerm: any;
      interestRate: any;
      organisationId: any;
    }) => {
      const payload = {
        propertyId: propertyDetails?.propertyId,
        organisationId: organisationId,
        loanAmount: loanAmount,
        loanTerm: loanTerm,
        interestRate: interestRate,
      };
      try {
        setAmortizationLoading(true);
        await postAmortizationCalculator(payload);
        setAmortizationLoading(false);
        setAmortizationFailLoading(false);
      } catch (err) {
        console.log(err);
        setAmortizationFailLoading(true);
      }
    }
  );

  const wholesaledebounce = useDbounce(
    100,
    async ({
      afterRepairValue,
      costOfRepairs,
      assignmentFee,
      Profitmargin,
      profileInfo,
    }: {
      afterRepairValue: any;
      costOfRepairs: any;
      assignmentFee: any;
      Profitmargin: any;
      profileInfo: any;
    }) => {
      const payload = {
        propertyId: propertyDetails?.propertyId,
        organisationId: profileInfo.organisationId,
        afterRepairValue: afterRepairValue,
        costOfRepairs: costOfRepairs,
        assignmentFee: assignmentFee,
        profitMargin: Profitmargin,
      };
      try {
        setWholesaleLoading(true);
        await postWholesaleCalculator(payload);
        setWholesaleLoading(false);
        setWholesaleFailLoading(false);
      } catch (err) {
        console.log(err);
        setWholesaleFailLoading(true);
      }
    }
  );

  const maxAllowableOffer = useMemo(() => {
    if (
      afterRepairValue == null ||
      costOfRepairs == null ||
      assignmentFee == null ||
      !Profitmargin
    ) {
      return 0;
    }
    return afterRepairValue * Profitmargin - costOfRepairs - assignmentFee;
  }, [afterRepairValue, costOfRepairs, assignmentFee, Profitmargin]);

  const loanAmount = parseFloat(
    (watch("loanAmount") ?? "").toString().replace(/,/g, "")
  );
  const loanTerm = parseFloat(
    (watch("loanTerm") ?? "").toString().replace(/,/g, "")
  );
  const interestRate = parseFloat(
    (watch("interestRate") ?? "").toString().replace(/,/g, "")
  );

  const monthlyPayment = useMemo(() => {
    if (
      isNaN(loanAmount) ||
      loanTerm == null ||
      interestRate == null ||
      !isValid
    ) {
      return;
    }
    return calculatePaymentPerPeriod(
      loanAmount,
      interestRate / 100,
      loanTerm,
      true
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const amortizationSchedule = useMemo(() => {
    if (
      !loanAmount ||
      !loanTerm ||
      loanTerm > 60 ||
      !interestRate ||
      !isValid
    ) {
      return [];
    }
    return calculateAmortizationSchedule(
      loanAmount,
      interestRate / 100,
      loanTerm,
      false
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  useEffect(() => {
    if (profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_VIEWER) {
      wholesaledebounce({
        afterRepairValue,
        costOfRepairs,
        assignmentFee,
        Profitmargin,
        profileInfo,
      });
    }
  }, [
    afterRepairValue,
    costOfRepairs,
    assignmentFee,
    Profitmargin,
    profileInfo,
  ]);

  useEffect(() => {
    if (profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_VIEWER) {
      amortizationdebounce({
        loanAmount,
        loanTerm,
        interestRate,
        organisationId: profileInfo?.organisationId,
      });
    }
  }, [loanAmount, loanTerm, interestRate, profileInfo]);

  useEffect(() => {
    const payload = {
      orgId: profileInfo.organisationId,
      id: propertyDetails?.propertyId,
    };
    dispatch(fetchWholesaleCalculatorAction(payload));
    dispatch(fetchAmortizationCalculatorAction(payload));
  }, [propertyDetails?.propertyId, profileInfo]);

  useEffect(() => {
    setWholesaleLoading(wholesaleCalculatorloading);
    if (wholesaleCalculatorData) {
      const { afterRepairValue, costOfRepairs, assignmentFee, profitMargin } =
        wholesaleCalculatorData;
      if (afterRepairValue || afterRepairValue === 0) {
        setValue("afterRepairValue", wholesaleCalculatorData.afterRepairValue, {
          shouldTouch: true,
        });
      } else {
        setValue("afterRepairValue", null, { shouldTouch: false });
      }
      if (costOfRepairs || costOfRepairs === 0) {
        setValue("costOfRepairs", wholesaleCalculatorData.costOfRepairs, {
          shouldTouch: true,
        });
      } else {
        setValue("costOfRepairs", null, { shouldTouch: false });
      }
      if (assignmentFee || assignmentFee === 0) {
        setValue("assignmentFee", wholesaleCalculatorData.assignmentFee, {
          shouldTouch: true,
        });
      } else {
        setValue("assignmentFee", null, { shouldTouch: false });
      }
      if (profitMargin) {
        setValue(
          "profitMargin",
          parseFloat(wholesaleCalculatorData.profitMargin),
          { shouldTouch: true }
        );
      }
    }
  }, [wholesaleCalculatorData, setValue, wholesaleCalculatorloading]);

  useEffect(() => {
    setAmortizationLoading(amortizationCalculatorloading);
    if (amortizationsaleCalculatorData) {
      console.log(
        "amortizationsaleCalculatorData",
        amortizationsaleCalculatorData
      );

      const { loanAmount, interestRate, loanTerm } =
        amortizationsaleCalculatorData;

      if (loanAmount || loanAmount === 0) {
        setValue("loanAmount", loanAmount, { shouldTouch: true });
      } else {
        setValue("loanAmount", null, { shouldTouch: false });
      }
      if (interestRate || interestRate === 0) {
        setValue("interestRate", interestRate, {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("interestRate", "", { shouldTouch: false });
      }
      if (loanTerm || loanTerm === 0) {
        setValue("loanTerm", loanTerm, {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("loanTerm", "", { shouldTouch: false });
      }
    }
  }, [amortizationsaleCalculatorData, setValue, amortizationCalculatorloading]);

  return (
    <div className={` ${styles.overview}`}>
      <div className="wrapper">
        <ShadowBox
          title="Wholesale Calculator"
          subTitle="Easily make the right wholesale offer with confidence."
          rightTitle="Max Allowable Offer (MAO):"
          rightSubTitle={
            maxAllowableOffer || maxAllowableOffer === 0
              ? formatNewCurrency(maxAllowableOffer)
              : "-"
          }
          showstatus
          loading={wholesaleLoading}
          failLoading={wholesaleFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="currency"
                label="After Repair Value (ARV)*"
                register={register}
                name="afterRepairValue"
                placeholder="Enter the ARV"
                errors={errors}
                prefix={<CurrencyDollar />}
                control={control}
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="currency"
                label="Cost of Repairs*"
                register={register}
                name="costOfRepairs"
                placeholder="Enter the repairs"
                errors={errors}
                prefix={<CurrencyDollar />}
                control={control}
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="currency"
                label="Assignment Fee*"
                register={register}
                name="assignmentFee"
                placeholder="Enter the assignment fee"
                errors={errors}
                prefix={<CurrencyDollar />}
                control={control}
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <DropDown
                options={ProfitMarginOptions}
                label="Profit Margin*"
                control={control}
                name="profitMargin"
                errors={errors}
                placeholder="70%"
                defaultValue={ProfitMarginOptions[0].value}
                handleOnFocus={(e: any) => handleOnFocus(e)}
              ></DropDown>
            </div>
          </div>
        </ShadowBox>
        <ShadowBox
          title="Amortization Calculator"
          subTitle="Modify the values to quickly analyze a deal."
          rightTitle="Monthly Payment:"
          rightSubTitle={
            monthlyPayment || monthlyPayment === 0
              ? formatNewCurrency(monthlyPayment)
              : "-"
          }
          loading={amortizationLoading}
          showstatus
          failLoading={amortizationFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="currency"
                label="Loan Amount*"
                register={register}
                name="loanAmount"
                placeholder="Enter the loan amount"
                errors={errors}
                prefix={<CurrencyDollar />}
                control={control}
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Loan Term*"
                register={register}
                name="loanTerm"
                placeholder="Enter number of years"
                errors={errors}
                min={0}
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Interest Rate*"
                register={register}
                name="interestRate"
                placeholder="Enter the interest rate"
                errors={errors}
                prefix={<Percent />}
              ></Input>
            </div>
          </div>
          {amortizationSchedule.length !== 0 && (
            <div className="calculator-table">
              {" "}
              <CustomTable
                rows={amortizationSchedule}
                columns={amortizationScheduleColumns}
                tableStriped
              />
            </div>
          )}
        </ShadowBox>
        <ShadowBox
          title="Creative Finance Calculator"
          subTitle="Perform a deep analysis of your creative finance deal."
        >
          <CreativeFinance />
        </ShadowBox>
      </div>
    </div>
  );
};
export default CalculatorTab;
