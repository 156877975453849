import { CircularProgress, MenuItem, SwipeableDrawer } from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Close from "../../../assets/icons/cross";
import DotVertical from "../../../assets/icons/dotsVertical";
import Edit from "../../../assets/icons/edit";
import Save from "../../../assets/icons/save";
import Trash from "../../../assets/icons/trash";
import otto from "../../../assets/images/otto.svg";
import Button from "../../../atoms/Button";
import Loader from "../../../atoms/Loader";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { getProfileInfo } from "../../../components/profileMenu/selectors";
import FilterDropdown from "../../../organisms/filterDropdown/FilterDropdown";
import { formartNumberAsLocalString, formatPhoneNumber } from "../../../utils/utils";
import { ResponseGenerator } from "../property.saga";
import styles from "./LeadDetailsModal.module.scss";
import { deletePropertyComment, getPropertyComment, postPropertyComment, putPropertyComment } from "../../../organisms/propertySearchFilter/PropertyService"
import { Link } from "react-router-dom";
import LinkIcon from "../../../assets/icons/link";
import { CustomError } from "../../../organisms/addLeads/interface";

interface LeadActivityDrawerProps {
  setShowpopup: (value: boolean) => void;
  showCommentBox: boolean;
  propertyId: number | string;
  type:string
}

const LeadActivityDrawer = ({
  setShowpopup,
  showCommentBox,
  propertyId,
  type
}: LeadActivityDrawerProps) => {
  // STATES
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [totalCount, setTotalCount] = useState(0);
 const[ count,setCount]=useState(0)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorVisible, setEditorVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<string>("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const CHARACTER_LIMIT = 2000;
  // FUNCTIONS
  const fetchMessages = useCallback(
    async (id: string | number, pageNumber = 1) => {
      try {
        setNewLoading(true);
      const data= {
          index: pageNumber,
          size: 10,
        }
        const response: ResponseGenerator = await getPropertyComment(id,data,type)
        setMessages((messages) => [...messages, ...response?.data?.result]);
        setTotalCount(response?.data?.totalCount);
      } catch (error: unknown) {
        toast.error("Couldn't fetch messages.");
      } finally {
        setNewLoading(false);
      }
    },
    []
  );

  const postMessage = useCallback(
    async (id: string | number, message: string) => {
      try {
        setLoading(true);
        const data = {
          propertyId: id,
          body: message,
        };
        const response: ResponseGenerator = await postPropertyComment(id, data,type);
        setMessages((messages) => [response.data, ...messages]);
        setEditorVisible(false);
        setEditorState(EditorState.createEmpty());
      } catch (error: any) { 
        console.log(error,"error")
        toast.error((error as CustomError)?.response?.data?.message);
      } finally {
        setLoading(false); 
      }
    },
    []
  );

  const updateMessage = useCallback(
    async (commentId: string | number | undefined, message: string) => {
      try {
        setLoading(true);
        const data={
          body: message,
        }
        await putPropertyComment(commentId, data);
        setMessages((messages) =>
          messages.map((item) => {
            if (item.commentId === commentId) {
              item.comment = message;
            }
            return item;
          })
        );
      setEditorVisible(false);
       setEditorState(EditorState.createEmpty());
      } catch (error: unknown) {
        toast.error("Couldn't update the message.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const deleteMessage = useCallback(async (commentId: string | number) => {
    try {
      setLoading(true);
      await deletePropertyComment(commentId);
      setMessages((messages) =>
        messages.filter((item) => item.commentId !== commentId)
      );
      setTotalCount((currentCount) => currentCount - 1);
    } catch (error: unknown) {
      toast.error("Couldn't delete the message.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEditorStateChange = (newState: EditorState) => {
    const plainText = newState?.getCurrentContent().getPlainText();
    const content = plainText.length;
    setCount(content)
    setEditorState(newState);
  };

  const handleUpdateClick = useCallback(() => {
    if (!editorState.getCurrentContent().getPlainText().trim()) {
      return;
    }
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    updateMessage(selectedCommentId, htmlContent);
    setSelectedCommentId(""); 
  }, [editorState, selectedCommentId, updateMessage]);

  const handlePostClick = useCallback(() => {
    if (!editorState.getCurrentContent().getPlainText().trim()) {
      return;
    }
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    postMessage(propertyId, htmlContent);
  }, [editorState, postMessage, propertyId]);

  const handleClose = useCallback(() => {
    setShowpopup(false);
    setEditorVisible(false);
    setSelectedCommentId("");
    setEditorState(EditorState.createEmpty());
    if (messageContainerRef?.current !== null) {
      const height = messageContainerRef?.current?.scrollHeight;
      messageContainerRef.current.scrollTo(0, 0);
    }
  }, [setShowpopup]);

  const handleOpen = useCallback(() => {
    setShowpopup(true);
  }, [setShowpopup]);

  const handleMenuClose = useCallback(() => {
    setSelectedCommentId("");
    setMenuAnchorEl(null);
  }, []);

  const handleEditMenuItemClick = useCallback((message: any) => {
    const { comment, body } = message;
    const { contentBlocks, entityMap } = convertFromHTML(comment || body);

    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );

    setMenuAnchorEl(null);
    setEditorState(newEditorState);
    setEditorVisible(true);
  }, []);

  const handleDeleteButtonClick = useCallback(async () => {
    await deleteMessage(selectedCommentId);
    setShowDeleteModal(false);
    setSelectedCommentId("");
  }, [deleteMessage, selectedCommentId]);

  // EFFECTS
  useEffect(() => {
    if (!propertyId) return;
    fetchMessages(propertyId);
  }, [fetchMessages, propertyId]);
 
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={showCommentBox}
      onClose={handleClose}
      onOpen={handleOpen}
      className="commentDrawer"
      hideBackdrop={true}
      variant="persistent"
    >
      <InfiniteScroll
        dataLength={messages?.length}
        next={() =>
          fetchMessages(propertyId, Math.floor(messages.length / 10) + 1)
        }
        hasMore={messages.length < totalCount}
        scrollableTarget={"leadActivityDrawerList"}
        loader={null}
      >
        <div className={`${styles.leadsDetails}`}>
          <div
            className={`flex alignCenter justifySpaceBetween ${styles.leadsDetails__header}`}
          >
            <h2>Activity</h2>
            <span role="link" onClick={handleClose}>
              <Close />
            </span>
          </div>
          <div className={` ${styles.leadsDetails__body}`}>
            <div className={` ${styles.messageInput}`}>
              {!editorVisible && (
                <input
                  type="text"
                  placeholder="Write an update..."
                  onClick={() => {
                    setEditorVisible(true);
                    setEditorState(EditorState.moveFocusToEnd(editorState));
                    setSelectedCommentId("");
                  }}
                />
              )}
              {editorVisible && (
                <div id="editor" className={` ${styles.editor}`}>
                  <Editor
                  editorState={editorState}
                    wrapperClassName="wrapperClassName"
                    editorClassName="lead-activity-editor"
                    onEditorStateChange={handleEditorStateChange}
                    // onEditorStateChange={setEditorState}
                    handlePastedText={() => {
                      setTimeout(() => {
                        const editorNode = document.getElementsByClassName(
                          "lead-activity-editor"
                        )[0];
                        editorNode.scroll(0, editorNode.scrollHeight);  
                      }, 0);
                      return false;
                    }}
                    onBlur={() => {
                      if (editorState.getCurrentContent().hasText()) return;
                      setEditorVisible(false);
                    }}
                    toolbar={{
                      options: ["inline", "list"],
                      list: { options: ["ordered", "unordered"] },
                    }}
                    spellCheck={true}
                  />
                </div>
              )}
              <div className={` flex justifyEnd alignCenter ${styles.editorBtn}`}>
                  {editorVisible && (
                    <>
                    <div className={` ${styles.characterLimit} ${(CHARACTER_LIMIT-count<300) && (styles.orange + " " + styles.bigger)} ${(CHARACTER_LIMIT-count<=0) && styles.red} ${(CHARACTER_LIMIT-count <= -100) && styles.noCircle}`}>
                  {CHARACTER_LIMIT-count < 300 && (
                    <span className={` ${styles.characterLimit__count} ${(CHARACTER_LIMIT-count<=0) && styles.red}`}>{CHARACTER_LIMIT-count !== 0 ? formartNumberAsLocalString(CHARACTER_LIMIT-count) : "0"}</span>
                  )}
                  {CHARACTER_LIMIT-count > -100 && (
                    <CircularProgress 
                      variant="determinate" 
                      value={Math.min(Math.max((1 - (CHARACTER_LIMIT - count) / CHARACTER_LIMIT) * 100, 0), 100)} 
                    />
                  )}
                  </div>
                    </>
                )}
                {editorVisible && (
                  <Button
                    prefix={<Save />}
                    action={() =>
                      selectedCommentId && !menuAnchorEl && !showDeleteModal
                        ? handleUpdateClick()
                        : handlePostClick()
                    }
                    className="primary animated"
                    label={
                      selectedCommentId && !menuAnchorEl && !showDeleteModal
                        ? "Update"
                        : "Post"
                    }
                    disabled={loading || count > CHARACTER_LIMIT}
                  />
                )}
              </div>
            </div>
            {loading && !showDeleteModal && (
              <div className={`${styles.loaderWrapper}`}>
                <Loader />
              </div>
            )}
            <div
              ref={messageContainerRef}
              className={` ${styles.messageScroll}`}
              id="leadActivityDrawerList"
            >
              {messages?.map((message: any, index) => {
                const {
                  created_by = "",
                  createdBy = "",
                  creatorType = "",
                  comment = "",
                  CreatedAt = "",
                  createdAt = "",
                  body = "",
                  number = "",
                  commentId,
                  campaignId="",
                  campaignName=""
                } = message;

                const userOwnMessage =
                  (created_by || createdBy) === profileInfo.id;

                return (
                  <div
                    key={index}
                    className={`flex ${styles.message} ${
                      userOwnMessage ? styles.messageSender : ""
                    }`}
                  >
                    {creatorType === "BOT" && (
                      <div className={` ${styles.message__logo}`}>
                        <img src={otto} alt="otto" />
                      </div>
                    )}
                    <div className={` ${styles.message__inner}`}>
                      <div
                        className={`flex alignCenter justifySpaceBetween ${styles.header}`}
                      >
                        {creatorType === "BOT" ? (
                          <p>
                            OttoLeads{" "}
                            <small> {formatPhoneNumber(number)} </small>
                          </p>
                        ) : (
                          <p>{userOwnMessage ? "You" : createdBy}</p>
                        )}

                        <span>
                          {new Date(CreatedAt || createdAt).toLocaleString()}
                        </span>
                      </div>
                      <div className={`${styles.msgBodyMain} flex`}>
                        <div
                          className={`${styles.msgBody}`}
                          dangerouslySetInnerHTML={{
                            __html: comment || body,
                          }}
                        ></div>
                        {userOwnMessage && (
                          <>
                            <Button
                              className="icon"
                              prefix={<DotVertical />}
                              action={(event: any) => {
                                if (selectedCommentId) return;
                                setSelectedCommentId(commentId);
                                setMenuAnchorEl(event.currentTarget);
                              }}
                            />
                            {selectedCommentId === commentId && (
                              <FilterDropdown
                                handleClose={handleMenuClose}
                              anchorEl={menuAnchorEl}
                                sizeList
                              >
                                <div className="dflex filterInfo">
                                  <ul className="filterList">
                                    <MenuItem
                                      onClick={() =>
                                        handleEditMenuItemClick(message)
                                      }
                                      disableRipple
                                    >
                                      <Edit />
                                      <span>Edit</span>
                                    </MenuItem>
                                    {/* <MenuItem
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setMenuAnchorEl(null);
                                      }}
                                      disableRipple
                                    >
                                      <Trash />
                                      <span>Delete</span>
                                    </MenuItem> */}
                                  </ul>
                                </div>
                              </FilterDropdown>
                            )}
                          </>
                        )}
                      </div>
                        {creatorType === "BOT" &&
                      <p className={`${styles.campaignName}`} >
                        <Link to ={`/campaigns/${campaignId}`}  target="_blank"><span role="link">{campaignName}</span> <LinkIcon/></Link>
                        </p>}
                    </div>
                  </div>
                );
              })}
            </div>
            {newLoading && !showDeleteModal && (
              <div className={`${styles.loaderWrapper}`}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </InfiniteScroll>

      <ConfirmationModal
        isOpen={showDeleteModal}
        setIsOpen={(value:any) => {
          setSelectedCommentId("");
          setShowDeleteModal(value);
        }}
        buttonComponent={
          <Button
            label="Yes, Delete"
            className="red xl full "
            action={handleDeleteButtonClick}
            disabled={loading}
            clicked={loading}
          />
        }
      >
        <h2>Delete Message</h2>
        <p>
          Are you sure you want to delete this message? <br />
          This action cannot be undone.
        </p>
      </ConfirmationModal>
    </SwipeableDrawer>
  );
};
export default LeadActivityDrawer;
